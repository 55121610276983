import { useContext, useState } from "react";
import { IonInput, IonButton, IonCard, IonCardContent, IonToast } from "@ionic/react";
import { BOTTLERS_KEY } from "../../data/BottlerUtils";
import { UidContext } from "../../hooks/UserDetailsContexts";
import { createGenericListEntry } from "../../data/dataArrayUtils";
import {DataContext} from '../../hooks/DataContext'


const AddBottler = () => {

    const [uid] = useContext(UidContext);
    const [showErrorToast, setShowErrorToast] = useState('');
    const {bottlers,showAddBottler, setShowAddBottler} = useContext(DataContext);
    const [name, setName] = useState('');

    const saveBottler = () => {

        try {
            createGenericListEntry(uid, BOTTLERS_KEY, bottlers, name);
            console.log("saved Bottler: " + name);
            setName("");
            setShowAddBottler(false);
        }
        catch (e : any) {
            console.log("error saving Bottler: " + e)
            setShowErrorToast(e)
        }
    }

    return (
        <>
            <IonToast isOpen={showErrorToast > ''}
                onDidDismiss={() => setShowErrorToast('')}
                message={showErrorToast}
                duration={1500}
                position="bottom"
                color="warning"
            />
            {showAddBottler ? showAddForm() : ""}
        </>
    );

    function showAddForm() {
        return (
            <IonCard>
                <IonCardContent>
                    <IonInput clearInput={true} name="name" value={name}
                        placeholder="Name" onIonChange={e => setName(e.detail.value!)}></IonInput>
                    <IonButton onClick={saveBottler}>Save</IonButton>
                    <IonButton color="light" onClick={() => setShowAddBottler(false)}>Cancel</IonButton>
                </IonCardContent>
            </IonCard>
        )
    }
}

export default AddBottler;

