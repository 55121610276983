import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonMenuButton, IonPage, IonRow, IonThumbnail, IonToolbar } from "@ionic/react"
import { logoIonic, logoReact } from "ionicons/icons"

var pjson = require('../../../package.json');

export default function About() {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    About mywhisky.cloud
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>mywhisky.cloud</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <p>
                            Copyright &copy; {new Date().getFullYear()} Seth Osher.
                        </p>
                        Version {pjson.version}
                        <hr/>

                        Designed as a tool for maintaining your whisky collection and keeping it handy.
                        This is a PWA (progressive web app) so you can save this bookmark to your home phone's
                        home screen and run it locally.
                        <hr/>
                        Your data is your own, but it is saved in the cloud
                        and automatically synced to any devices you have logged into with the same account.
                        It is not available to anyone other users of the application, but the maintainers of
                        the application can see it. Don't put anything in this application you would be embarassed
                        to have someone else see. See also our Terms of Service and Privacy Policy below.
                        <hr/>
                        Yes we use cookies. But just to save your login data. We also save your whisky collection in
                        your
                        browsers data store for offline use. See also our Cookies Policy below.
                        <hr/>

                        Built with <a href="https://ionicframework.com/">Ionic</a>, <a
                        href="https://reactjs.org">React</a>, <a href="https://material-ui.com/">Material-UI</a>, and <a
                        href="https://firebase.google.com/">Firebase</a>.
                        Hosted with <a href="https://pages.cloudflare.com/">Cloudflare Pages</a>.
                        <hr/>

                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <a target="_blank" rel="noreferrer" href="https://ionicframework.com/">
                                        <IonIcon size="large" icon={logoIonic}/>
                                    </a>
                                </IonCol>
                                <IonCol>
                                    <a target="_blank" rel="noreferrer" href="https://reactjs.org/">
                                        <IonIcon size="large" icon={logoReact}/>
                                    </a>
                                </IonCol>
                                <IonCol>
                                    <IonThumbnail>
                                        <a target="_blank" rel="noreferrer" href="https://material-ui.com/">
                                            <img alt="material-ui" src="https://mui.com/static/favicon.ico"/>
                                        </a>
                                    </IonThumbnail>

                                </IonCol>
                                <IonCol>
                                    <IonThumbnail>
                                        <a target="_blank" rel="noreferrer" href="https://firebase.google.com/">
                                            <img alt="firebase"
                                                 src="https://www.gstatic.com/mobilesdk/160503_mobilesdk/logo/2x/firebase_28dp.png"/>
                                        </a>
                                    </IonThumbnail>

                                </IonCol>
                                <IonCol>
                                    <IonThumbnail>
                                        <a target="_blank" rel="noreferrer" href="https://pages.cloudflare.com/">
                                            <img alt="cloudflare"
                                                 src="https://pages.cloudflare.com/resources/logo/logo.svg"/>
                                        </a>
                                    </IonThumbnail>

                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <hr/>
                        <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a
                            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                        <div> Icon by <a href="https://freeicons.io/profile/3031">anju.p</a> on <a
                            href="https://freeicons.io">freeicons.io</a></div>
                        <div> Empty by Adrien Coquet from the Noun Project</div>
                        <div> gas gauge by Iconic from the Noun Project</div>
                        <div> gin by Nook Fulloption from the Noun Project</div>
                        <div> Whisky by Weltenraser from the Noun Project</div>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Legal stuff</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div>Our Terms of service are <a target="tos"
                                                         href={process.env.PUBLIC_URL + "tos.html"}> here</a>.
                        </div>
                        <div>Our Privacy Policy is <a target="privacy"
                                                      href={process.env.PUBLIC_URL + "privacy.html"}> here</a>.
                        </div>
                        <div>Our Cookie Policy is <a target="cookies"
                                                     href={process.env.PUBLIC_URL + "cookies.html"}> here</a>.
                        </div>

                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    )
}

