import { IonPage, IonContent } from "@ionic/react";
import { useState } from "react";

const axios = require('axios').default;

export default function Privacy() {
    const [externalFile, setExternalFile] = useState('');

    function getHTMLFile() {
        axios.get('/privacy.html').then(
            (response: any) => {
                setExternalFile(response.data)
            })
    }

    getHTMLFile();

    function getHtmlData(): any {
        return {
            __html: externalFile
        }
    }
    return (
        <IonPage>
            <IonContent>
                <div
                    dangerouslySetInnerHTML={getHtmlData()} >
                </div>
            </IonContent>
        </IonPage>
    )
}
