import React, { useState, useContext } from 'react';

import {
  IonContent, IonHeader, IonPage, IonToolbar, IonSearchbar,
  IonMenuButton, IonButtons, IonButton
} from '@ionic/react';
import './Wishlist.css';
import AddWishlist  from './WishlistAdd';
import WishlistList from "./WishlistList";
import { UidContext } from '../../hooks/UserDetailsContexts';

export default function Wishlist(){
  const [searchText, setSearchText] = useState('');
  const [uid] = useContext(UidContext);
  // const [username] = useContext(UsernameContext);


    function ShowWishList({searchText} : {searchText: string}) {
        return (
            <>
                <AddWishlist />
                <WishlistList searchText={searchText} />
            </>
        );
    }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          Wishlist
        </IonToolbar>
        {uid > '' ?
          <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} 
            placeholder="Search by name" showClearButton="always" showCancelButton="never"></IonSearchbar>
          : ""}

      </IonHeader>
      <IonContent class="wishlist">
        {uid > '' ?  <ShowWishList searchText={searchText} />
        :  <IonButton routerLink="/login">Login</IonButton>
        }
      </IonContent>

    </IonPage>
  );



}
