import React from "react"
import { useInView } from "react-intersection-observer"

/***************
 * See
 * https://stackoverflow.com/questions/67033068/how-to-reduce-render-time-while-rendering-huge-list-of-items-in-ionic-react
 * and
 * https://www.npmjs.com/package/virtual-scroll-component
 */

interface Props {
  height?: number
  children: React.ReactNode
}

export const VirtualScrollChild: React.FC<Props> = (props) => {
  const [ref, inView] = useInView()
  const style = {
    height: `${props.height ? props.height : 40}px`,
    overflow: "hidden",
  }
  return (
    <div style={style} ref={ref}>
      {inView ? props.children : null}
    </div>
  )
}

