import React, { useState, useContext } from 'react';

import {
  IonContent, IonHeader, IonPage, IonToolbar, IonSearchbar, IonMenuButton, IonButtons, IonButton
} from '@ionic/react';
import './Shelves.css';
import AddShelf  from './ShelfAdd';
import ShelfList from "./ShelfList";
import { UidContext } from '../../hooks/UserDetailsContexts';

export default function Shelves() {
  const [searchText, setSearchText] = useState('');
  const [uid] = useContext(UidContext);

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          Shelves
        </IonToolbar>
        {uid > '' ? 
          <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} 
            placeholder="Search by name" showClearButton="always" 
            showCancelButton="never"></IonSearchbar>
        : "" }

      </IonHeader>
      <IonContent class="shelves">
        {uid > '' ? 
           doShelves(searchText) 
           : <IonButton routerLink="/login">Login</IonButton>
        }
      </IonContent>


    </IonPage>
  );

  function doShelves(searchText: string) {
    return (
      <>
        <AddShelf />
        <ShelfList searchText={searchText} />
      </>
    );
  }
}
