import React, { useState, useContext } from 'react';
import {
  IonContent, IonHeader, IonPage, IonToolbar, IonSearchbar, IonMenuButton, IonButtons, IonButton, IonFab, IonFabButton, IonIcon
} from '@ionic/react';
import './Distilleries.css';
import AddDistillery from './DistilleryAdd';
import DistilleryList from "./DistilleryList";
import { add } from 'ionicons/icons';
import { UidContext } from '../../hooks/UserDetailsContexts';
import {DataContext} from '../../hooks/DataContext'

/**
 * The Distilleries page
 * 
 * @returns The body
 */
export default function Distilleries ()  {
  const [searchText, setSearchText] = useState('');
  const [uid] = useContext(UidContext);
  // const [userName] = useContext(UsernameContext);
  const {showAddDistillery, setShowAddDistillery} = useContext(DataContext);

  return (

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {/* Show the menu */}
              <IonMenuButton/>
            </IonButtons>
            Distilleries
          </IonToolbar>

          {/* Show the toolbar only if logged in (uid > "") */}
          {uid > "" ?
              <>
                <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                              placeholder="Search by name, region, or number" showClearButton="always"
                              showCancelButton="never"></IonSearchbar>
                {showAddDistillery ? <AddDistillery/> : ""}
              </>
              : ""}
        </IonHeader>

        {/* Set class for styling */}
        <IonContent class="distilleries">
          {/* Fab + button at bottom for  "adding" */}
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => setShowAddDistillery(true)}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>

          {/* Redirect to login if not logged in */}
          {uid > "" ?
              <DistilleryList searchText={searchText}/>
              : <IonButton routerLink="/login">Login</IonButton>
          }
        </IonContent>

      </IonPage>
  )

}

