import { createGenericListDetaults, loadGenericList, saveGenericList } from "./dataArrayUtils";
const DefaultWishlist = ["Macallan 25"];
export const WISHLIST_VERSION = 1;
export const WISHLIST_KEY = 'wishlist';

export async function createDefaultWishlist(uid: string) {
    createGenericListDetaults(uid, WISHLIST_KEY, WISHLIST_VERSION, DefaultWishlist);
}

export function saveWishlistList(uid: string, wishlistList: string[]) {
    saveGenericList(uid, WISHLIST_KEY, wishlistList);
}

export async function loadWishlistList(uid: string): Promise<string[]> {
    return loadGenericList(uid, WISHLIST_KEY);
}
