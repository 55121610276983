import firebase from "firebase/compat/app";
import 'firebase/compat/auth'


const loginUiConfig = {
    signInSuccessUrl: '/whiskies',
    signInFlow: 'redirect',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => true
    },
        // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: 'https://mywhisky.cloud/tos',
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign('https://mywhisky.cloud/privacy');
    }
  };
  
  export { loginUiConfig };
