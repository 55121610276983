import firebase from "firebase/compat/app";

/* To and from JSON from https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map */
export function replacer(key: string, value: any) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

export function reviver(key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

/*****
 * Generic functions for storing an array of strings as a singkle element in a document
 * Under the path users/uid/list/map.list
 * in firebase.
 */

/**
 * This class is used as a wrapper for the generic list for use in hooks
 */
export class GenericListHolder {
    list = [''];
}

export class GenericMapHolder {
    data = [{}];
}

/**
 * This interfce is a generic setter for the generic list
 */
export interface GenericListHolderSetter {
    (data: GenericListHolder): void;
}

export interface GenericMapHolderSetter {
    (data: GenericMapHolder): void;
}

export interface NumberSetter {
    (data: number): void;
}

/**
 * Save the contets of an array to the generic list in firebase as specified.
 * users/$uid/$key/map.list
 * 
 * @param uid The UID
 * @param listName  The list name key.
 * @param listData  The data to save
 */
export function saveGenericList(uid: string, listName: string, listData: string[]) {
    console.log("Saving list [" + listName + "]: " + listData.length);
    const collection = firebase.firestore().collection("users/" + uid + "/" + listName);
    const newDoc = collection.doc("map");
    const body = JSON.stringify(listData, replacer);
    newDoc.set({ list: body }, { merge: true });
}

export function subscribeGenericMap(uid: string, mapName: string, setData: GenericMapHolderSetter) {
    console.log("subscribe map [" + mapName + "] uid=" + uid);
    if (uid !== "") {

        firebase.firestore().collection("users/" + uid + "/" + mapName)
            .onSnapshot((snap) => {
                console.log("Whiskies map updated");
                const docs = new GenericMapHolder();
                while (docs.data.length > 0) docs.data.pop();
                snap.docs.forEach((doc: any) => {
                    docs.data.push(doc.data());
                })
                setData(docs);
            });

    } else {
        setData(new GenericMapHolder());
    }
}


/**
 * Subscribe to events on the list, data is set in the callback.
 * list is at the path: users/$uid/$key/map.list
 *
 * @param uid  The UID to user
 * @param listName THe list name key
 * @param setData The calback function on data updates.
 */
export function subscribeGenericList(uid: string, listName: string, setData: GenericListHolderSetter) {
    console.log("subscribe list [" + listName + "] uid=" + uid);
    if (uid !== "") {
        // This is the subscription
        firebase.firestore().doc("users/" + uid + "/" + listName + "/map").onSnapshot((snap) => {

            // These bits are the data extraction
            const allData = snap.get("list");
            if (!allData) return;
            const newList = JSON.parse(allData, reviver);

            // Sort the data (do it once, to avoid sorting for each repaint)
            newList.sort((a: string, b: string) => {
                const aL = a.toLowerCase();
                const bL = b.toLowerCase();
                return aL < bL ? -1 : (aL > bL ? 1 : 0);
            })

            // Return the new data
            const newData = new GenericListHolder();
            newData.list = newList;
            setData(newData);
        })
    } else {
        setData(new GenericListHolder());
    }
}

/**
 * Load a gneneric list and return the array of strings
 * List is at the path: users/$uid/$key/map.list
 * 
 * @param uid  The UID
 * @param listName The list name key
 * @returns The array of strings loaded
 */
export async function loadGenericList(uid: string, listName: string): Promise<string[]> {
    console.log("Loading list [" + listName + "]");
    const doc = firebase.firestore().doc("users/" + uid + "/" + listName + "/map");
    const docData = await doc.get();
    const data = docData.data();

    const res = JSON.parse(data?.list, reviver);

    // Sort the data (do it once, to avoid sorting for each repaint)
    res.sort((a: string, b: string) => {
        const aL = a.toLowerCase();
        const bL = b.toLowerCase();
        return aL < bL ? -1 : (aL > bL ? 1 : 0);
    })

    console.log("loaded list [" + listName + "] " + res.length);

    return res;
}

/**
 * Create a new generic list, and initialize it.
 * First a field "listName"+Version is checked on the user document users/$uid.listNameVersion 
 * versus the defaults version.  If they are different, the contents are merged.   
 * list is at the path: users/$uid/$key/map.list
 * 
 * TODO: this merge behavior is probably not right for an inplace upgrade of the data as it will probably
 * produce duplicates.
 * 
 * 
 * @param uid  The UID
 * @param listName  The List name Key
 * @param defaultsVersion The version to compare to
 * @param defaultList The defaiut values as an array
 */
export async function createGenericListDetaults(uid: string, listName: string, defaultsVersion: number, defaultList: string[]) {
    if (uid === '') return;
    const user = firebase.firestore().doc("users/" + uid);
    const doc = await user.get();
    const field = listName + "Version";
    const sVersion = doc.get(field);
    const v = Number.parseInt(sVersion);

    if (v !== defaultsVersion) {
        console.log("Initialising list [" + listName + "]");
        saveGenericList(uid, listName, defaultList);

        const data = {
            [field]: defaultsVersion
        };

        // Record version update
        user.set(data, { merge: true });
    }
}

/**
 * Add a new entry to the generic list
 * list is at the path: users/$uid/$key/map.list
 * 
 * @param uid The UID
 * @param listName The list name key
 * @param current The current contents of the list (to avoid reloading)
 * @param newEntry The new entry.
 */
export function createGenericListEntry(uid: string, listName: string, current: GenericListHolder, newEntry: string) {
    if (uid === "") { throw new Error("No user") }
    if (newEntry === '') {
        throw new Error("A name is required.");
    }

    if (current.list.indexOf(newEntry) > -1) {
        throw new Error(newEntry + " already exists.");
    }
    current.list.push(newEntry);

    saveGenericList(uid, listName, current.list);
}