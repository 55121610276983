import { useState, useContext } from 'react';

import {
  IonContent, IonHeader, IonPage, IonToolbar, IonMenuButton, IonButtons, IonButton, IonFabButton, IonIcon, IonCard, IonCardContent, IonToast, IonInput, IonImg, IonGrid, IonRow, IonCol, IonLabel, IonItem, IonRange, IonCardHeader
} from '@ionic/react';
import './WhiskyEdit.css';
import { camera, logoUsd, star, trash } from 'ionicons/icons';
import { UidContext } from '../../hooks/UserDetailsContexts';
import { DataContext } from "../../hooks/DataContext";
import { saveAddWhisky } from "../../data/WhiskyUtils";
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import { useHistory } from 'react-router-dom';
import {Autocomplete, TextField} from '@mui/material'

const { v4: uuidv4 } = require('uuid');

export default function WhiskyEdit() {
  const history = useHistory();

  const [uid] = useContext(UidContext);
  // const [username] = useContext(UsernameContext);
  const {distilleries,bottlers, shelves, whiskies, showAddWhisky, setShowAddWhisky} = useContext(DataContext);

  const [name, setName] = useState('');
  const [bottler, setBottler] = useState('');
  const [region, setRegion] = useState('');
  const [smws, setSmws] = useState('');
  const [shelf, setShelf] = useState('');
  const [rating, setRating] = useState(3.0);
  const [price, setPrice] = useState(3.0);
  const [distillery, setDistillery] = useState('');
  const [notes, setNotes] = useState('')
  const { takePhoto, photoBase64, setPhotoBase64 } = usePhotoGallery();
  const [uuid, setUuid] = useState('');

  const [errorToast, setErrorToast] = useState('');

  const comboBoxStyle = {
    width: "100%"
  }

  const ratingStyle = {
    width: "500"
  }

  const passthruStyle = {
    opacity: "85%"
  }

  const newWhisky = () => {
    setName("");
    setRegion("");
    setSmws("");
    setShelf("");
    setBottler("");
    setDistillery("");
    setNotes("")
    setPhotoBase64("");
    setRating(3);
    setPrice(3);
  }

  const doSetDistillery = (newDistillery: string) => {
    setSmws("");
    setRegion("");
    if (newDistillery.lastIndexOf(')') === newDistillery.length - 1) {
      console.log("Trimming " + newDistillery);
      const l = newDistillery.lastIndexOf('(');
      newDistillery = newDistillery.slice(0, l - 1);
      console.log("Trimmed to " + newDistillery);
    }
    setDistillery(newDistillery);
    distilleries.sortedDistilleryList.forEach((e: any) => {
      if (e.name === newDistillery) {
        setRegion(e.region);
        setSmws(e.smws);
      }
    })
  }

  const loadWhisky = () => {
    if (showAddWhisky === '' || showAddWhisky === uuid) return;

    whiskies.data.forEach((w: any) => {
      if (w.uuid === showAddWhisky) {
        console.log("Loading for edit " + w.uuid + " " + w.name);
        setUuid(w.uuid);
        setName(w.name);
        setRegion(w.region);
        setSmws(w.smws);
        setDistillery(w.distillery);
        setBottler(w.bottler);
        setRating(w.rating);
        setPhotoBase64(w.photo);
        setShelf(w.shelf);
        setPrice(w.price);
        setNotes(w.notes);
        return;
      }
    });
    console.log("Whisky " + showAddWhisky + " not found");
  }

  const saveWhisky = () => {

    var data = {
      name: name,
      nameLower: name.toLowerCase(),
      distillery: distillery,
      region: region,
      bottler: bottler,
      shelf: shelf,
      smws: smws,
      rating: rating,
      price: price,
      notes: notes,
      photo: photoBase64,
      uuid: showAddWhisky > "" ? showAddWhisky : uuidv4()
    }
    try {
      saveAddWhisky(uid, data);
      console.log("saved whisky: " + name + " - " + data.uuid)

      setPhotoBase64("");
      newWhisky();
      setShowAddWhisky("");
      history.push("/whiskies");
    }
    catch (e : any) {
      console.log("error saving whisky: " + e)
      setErrorToast(e);
    }
  }


  function AddWhiskyForm() {
    return (
      <>
        <IonCard style={passthruStyle}>
          <IonCardContent>
            <IonToast isOpen={errorToast > ''}
              onDidDismiss={() => setErrorToast('')}
              message={errorToast}
              duration={1500}
              position="bottom"
              color="warning"
            />

            {/* Name */}
            <IonItem>
              <IonLabel color="primary" position="floating">Whisky Name</IonLabel>
              <IonInput clearInput={true} name="name" value={name} placeholder="Name"
                autocapitalize="on" autocorrect="on" spellcheck={true} autofocus={true} required={true}
                onIonChange={e => setName(e.detail.value!)} />
            </IonItem>

            {/* Distillery */}
            <IonItem>
              <IonLabel color="primary" position="stacked"></IonLabel>
              <Autocomplete id="distillery" style={comboBoxStyle}
                options={distilleries.sortedDistilleryList.map((item: any) => {
                  return item.smws > '' ? item.name + " (" + item.smws + ")" : item.name
                })}
                clearOnEscape={true}
                value={smws > '' ? distillery + " (" + smws + ")" : distillery}
                onChange={(o, v: string) => doSetDistillery(v ? v : "")}
                renderInput={(params) => <TextField {...params} label="Distillery" variant="standard" />}
              />
            </IonItem>


            {/* Bottler */}
            <IonItem>
              <IonLabel color="primary" position="stacked"></IonLabel>
              <Autocomplete id="bottlers" style={comboBoxStyle}
                options={bottlers.list.map((item) => { return item; })}
                clearOnEscape={true}
                value={bottler}
                onChange={(o, v) => setBottler(v ? v : "")}
                renderInput={(params) => <TextField {...params} label="Bottler (empty for Distillery)" variant="standard" />}
              />
            </IonItem>

            {/* Notes */}
            <IonItem>
              <IonLabel color="primary" position="floating">Notes</IonLabel>
              <IonInput clearInput={true} name="notes" value={notes} placeholder="Notes"
                autocapitalize="sentences" autofocus={false} autocorrect="on" spellcheck={true} required={false}
                onIonChange={e => setNotes(e.detail.value!)} />
            </IonItem>

            {/* Shelf */}
            <IonItem>
              <IonLabel color="primary" position="stacked"></IonLabel>
              <Autocomplete id="shelf" style={comboBoxStyle}
                options={shelves.list}
                clearOnEscape={false}
                value={shelf}
                onChange={(o, v) => setShelf(v ? v : "")}
                renderInput={(params) => <TextField {...params} label="Shelf" variant="standard" />}
              />
            </IonItem>

            {/* Price */}
            <IonItem>
              <IonLabel>Price</IonLabel>
              <IonRange style={ratingStyle}
                min={0} max={5} step={1} snaps={true}
                pin={true} color="primary" value={price}
                onIonChange={(e) => {
                  setPrice(e.detail.value as any);
                }}>
                <IonIcon slot="start" size="small" icon={logoUsd}></IonIcon>
                <IonIcon slot="end" size="large" icon={logoUsd}></IonIcon>
              </IonRange>
            </IonItem>

            {/* Rating */}
            <IonItem>
              <IonLabel>Rating</IonLabel>
              <IonRange style={ratingStyle}
                min={0} max={5} step={1} snaps={true}
                pin={true} color="primary" value={rating}
                onIonChange={(e) => {
                  setRating(e.detail.value as any);
                }}>
                <IonIcon slot="start" size="small" icon={star}></IonIcon>
                <IonIcon slot="end" size="large" icon={star}></IonIcon>
              </IonRange>
            </IonItem>

            <IonButton onClick={saveWhisky}>Save</IonButton>
            <IonButton color="light" routerLink="/whiskies" routerDirection="back" onClick={() => {
              newWhisky();
              setShowAddWhisky("");
            }}>Cancel</IonButton>
          </IonCardContent>
        </IonCard>

        {/* Do phot as saperate card */}
        <IonCard style={passthruStyle}>
          <IonCardContent>
            <IonCardHeader>
              <h2>Photo</h2>
            </IonCardHeader>
            <IonGrid>
              <IonRow>
                <IonCol size="2"></IonCol>
                <IonCol size="5">
                  <IonButtons>
                    <IonFabButton onClick={() => takePhoto()}>
                      <IonIcon icon={camera}></IonIcon>
                    </IonFabButton>
                    <div>&nbsp;</div>
                    {/* Show delete button only if we have an image */}
                    <IonFabButton color="danger" disabled={photoBase64.length < 100}
                      onClick={() => setPhotoBase64("")}>
                      <IonIcon icon={trash}></IonIcon>
                    </IonFabButton>
                  </IonButtons>
                </IonCol>
                <IonCol size="2"></IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="1"></IonCol>
                <IonCol size="7">
                  {photoBase64.length > 0 ?
                    <IonImg src={photoBase64} />
                    : ""}
                </IonCol>
                <IonCol size="1"></IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </>
    )
  }

  /* Load the data if this is an edit ! */
  loadWhisky();

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
         {showAddWhisky > "" ? "Edit " : "New "} Whisky
        </IonToolbar>
      </IonHeader>

      <IonContent class="whisky-edit" overflow-scroll={true} scrollY={true} forceOverscroll={true}>

        {uid > "" ? <AddWhiskyForm />
          : <IonButton routerLink="/login">Login</IonButton>
        }
      </IonContent>


    </IonPage>
  );
}


