import { useState } from "react";
import { isPlatform } from '@ionic/react';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Preferences } from '@capacitor/preferences'

/*****
 * Taken and modified from the ionic sample app
 */
export function usePhotoGallery() {

  const [photoBase64, setPhotoBase64] = useState("");

  const takePhoto = async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        allowEditing: true,
        quality: 85,
        height: 1280,
        width: 720,
        presentationStyle: 'popover'
      });
      const fileName = new Date().getTime() + '.jpeg';
      console.log("got photo: " + fileName);
      const savedFileImage = await savePicture(cameraPhoto);
      console.log("Photo length: " + savedFileImage.base64Photo.length);
    }
    catch (e) {
      console.log("Photo error: " + e);
    }
  };

  const savePicture = async (photo: Photo): Promise<UserPhotoBase64> => {
    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await Preferences.get({
        key: photo.path!
      });
      base64Data = file.value as string;
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    setPhotoBase64(base64Data);
    console.log("Base64 size=" + base64Data.length);

    return {
      base64Photo: base64Data
    }


  };

  return {
    takePhoto,
    photoBase64,
    setPhotoBase64
  };
}

export interface UserPhotoBase64 {
  base64Photo: string;
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string')
      }
    };
    reader.readAsDataURL(blob);
  });
}
