import {
    IonItemSliding, IonCard, IonList, IonLabel, IonIcon, IonItemOptions,
    IonItemOption, IonItem, IonListHeader
} from "@ionic/react";
import { trash } from "ionicons/icons";
import { useContext } from "react";
import { saveBottlersList } from "../../data/BottlerUtils";
import { UidContext } from "../../hooks/UserDetailsContexts";
import {DataContext} from '../../hooks/DataContext'


export default function BottlerList (searchText: any) {
    const [uid] = useContext(UidContext);
    const {bottlers} = useContext(DataContext);

    const passthruStyle = {
        opacity: "85%"
    }


    /* Filter the list */
    const searchLower = searchText.searchText.toLowerCase();

    /* Case-insensitive matching */
    function itemMatch(item: any, searchText: string) {
        return searchText.length === 0 || item.toLowerCase().indexOf(searchText) >= 0;
    }

    /* Each item ... slider to delete */
    function addItem(item: any) {
        return (
            <IonItemSliding key={"sliding-" + item}>
                <IonItemOptions>
                    <IonItemOption color="danger" onClick={() => deleteItem(item)}>
                        <IonIcon slot="icon-only" icon={trash} />
                    </IonItemOption>
                </IonItemOptions>
                <IonItem key={item} onClick={() => console.log("Selected " + item)}>
                    <IonLabel><h3>{item}</h3></IonLabel>
                </IonItem>
            </IonItemSliding>
        )
    }

    /* Delete function - remove from the conntext array and re-save */
    function deleteItem(key: string) {
        const i = bottlers.list.indexOf(key);
        if (i > -1) {
            bottlers.list.splice(i, 1);
            saveBottlersList(uid, bottlers.list);
            console.log("DeleteItem " + key);
        }

    }

    return (
        <IonCard style={passthruStyle}>
            <IonList>
                <IonListHeader >
                    <IonLabel color="primary">{bottlers.list.length} Bottlers</IonLabel>
                </IonListHeader>

                {bottlers.list.map((item) => {
                    return itemMatch(item, searchLower) ? addItem(item) : "";
                })}

            </IonList>
        </IonCard>
    )

}


