import {
    IonItemSliding, IonCard, IonList, IonLabel, IonIcon, IonItemOptions,
    IonItemOption, IonItem, IonListHeader, IonThumbnail, IonButton, IonModal, IonFab, IonFabButton
} from "@ionic/react";
import { createOutline, createSharp, trash,  closeCircleOutline, cart} from "ionicons/icons";
import { useContext, useState } from "react";
import { UidContext } from "../../hooks/UserDetailsContexts";
import { deleteWhisky, saveAddWhisky } from "../../data/WhiskyUtils";
import { WISHLIST_KEY } from "../../data/WishlistUtils";
import { createGenericListEntry } from "../../data/dataArrayUtils";
import { useHistory } from "react-router-dom";
import { VirtualScrollChild } from "../../components/VirtualScrollChild";
import React from 'react';
import {DataContext} from '../../hooks/DataContext'


export default function WhiskyList(searchText: any) {
    const history = useHistory();

    const [uid] = useContext(UidContext);
    const {whiskies, wishlist,setShowAddWhisky} = useContext(DataContext);
    const [hideEmpties, setHideEmpties] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [currentWhisky, setCurrentWhisky] = useState<any>({});
    const [showImgModal, setShowImgModal] = useState(false);

    const searchLower = searchText.searchText.toLowerCase();

    const passthruStyle = {
        opacity: "85%"
    }


    //         'object-fit': 'contain',
    const modalImageStyle = {
        'object-fit': 'contain',
        width: 'auto !important',
        maxHeight: '100%',
        maxWidth: '100%',
        border: 0,
        padding: 0
    }

    function itemMatch(item: any, searchText: string) {
        return (searchText.length === 0
                || item.nameLower?.indexOf(searchText) >= 0
                || item.region?.toLowerCase().indexOf(searchText) >= 0
                || item.distillery?.toLowerCase().indexOf(searchText) >= 0
                || item.smws?.toLowerCase().indexOf(searchText) >= 0
                || item.notes?.toLowerCase().indexOf(searchText) >= 0
                || item.bottler?.toLowerCase().indexOf(searchText) >= 0
                || item.shelf?.toLowerCase().indexOf(searchText) >= 0)
            && (!hideEmpties || item.shelf !== "Empties");
    }

    function addItem(item: any) {
        return (
            <VirtualScrollChild key={"vs" + item.uuid} height={item.notes > "" ? 133 : 110}>
                <IonItemSliding key={"sliding" + item.uuid} id={"sl-" + item.uuid}>
                    <IonItemOptions>
                        <IonItemOption color="primary" onClick={() => {
                            wishlistItem(item);
                            closeSliding("sl-" + item.uuid)
                        }}>
                            <IonIcon slot="icon-only" ios={cart} md={cart}/>
                        </IonItemOption>
                        <IonItemOption color="primary" onClick={() => {
                            emptyItem(item);
                            closeSliding("sl-" + item.uuid)
                        }}>
                            <IonIcon slot="icon-only" icon={closeCircleOutline}/>
                        </IonItemOption>
                        <IonItemOption color="primary" routerLink="/WhiskyEdit" onClick={() => {
                            editWhisky(item.uuid, "sl-" + item.uuid);
                        }}>
                            <IonIcon slot="icon-only" ios={createOutline} md={createSharp}/>
                        </IonItemOption>
                    </IonItemOptions>
                    <IonItemOptions side="start">
                        <IonItemOption color="danger" onClick={() => deleteItem(item.uuid)}>
                            <IonIcon slot="icon-only" icon={trash}/>
                        </IonItemOption>
                    </IonItemOptions>
                    <IonItem key={item.uuid} onClick={() => console.log("Selected " + item.name)}>
                        <IonThumbnail slot="start" onClick={(e: any) => {
                            e.persist();
                            setCurrentImg(item.photo);
                            setCurrentWhisky(item);
                            setShowImgModal(true);
                        }}>
                            {item.photo?.length > 0 ?
                                <img alt="" src={item.photo}/>
                                : ""}
                        </IonThumbnail>
                        <IonLabel><h3>{item.name}</h3>
                            <p>{item.distillery} {item.region > "" ? " - " + item.region : ""} </p>
                            {item.notes > "" ?
                                <p> {item.notes} </p>
                                : ""}
                            <p>{getStars(item.rating)} - {getDollars(item.price)}</p>
                            <p>{item.bottler > "" ? item.bottler + " - " : ""} Location: {item.shelf > "" ? item.shelf : ""}</p>

                        </IonLabel>
                    </IonItem>
                </IonItemSliding>
            </VirtualScrollChild>

        )
    }

    function closeSliding(slidingId: any) {
        const sliding = document.getElementById(slidingId) as any;
        sliding?.close();
    }

    function deleteItem(key: string) {
        deleteWhisky(uid, key);
    }

    function getStars(count: number) {
        var r = "";
        for (var c = 0; c < count; c++) {
            r = r + '\u2605';
        }
        return r;
    }

    function getDollars(count: number) {
        var r = "";
        for (var c = 0; c < count; c++) {
            r = r + "$";
        }
        return r;
    }

    function editWhisky(item: string, slidingItem: string): void {
        closeSliding(slidingItem);
        setShowAddWhisky(item);
        history.push("/whiskyedit");
    }

    function wishlistItem(item: any): void {
        if (item.bottler > "")
            createGenericListEntry(uid, WISHLIST_KEY, wishlist, item.name + " - " + item.distillery + " (" + item.bottler + ")");
        else
            createGenericListEntry(uid, WISHLIST_KEY, wishlist, item.name + " - " + item.distillery);

    }

    function emptyItem(item: any): void {
        item.shelf = "Empties";
        saveAddWhisky(uid, item);
    }


    return (
        <>
            <IonFab vertical="top" horizontal="end" slot="fixed">
                {hideEmpties ?
                    <IonFabButton color="light" onClick={() => {
                        setHideEmpties(false);
                    }}>
                        {/*<PlaylistRemoveIcon />*/}
                        <IonIcon icon={closeCircleOutline}></IonIcon>
                    </IonFabButton>
                    :
                    <IonFabButton onClick={() => {
                        setHideEmpties(true);
                    }}>
                        {/*<PlaylistRemoveIcon />*/}
                        <IonIcon icon={closeCircleOutline}></IonIcon>
                    </IonFabButton>
                }
            </IonFab>

            <IonCard style={passthruStyle}>
                <IonModal isOpen={showImgModal}>
                    <IonButton onClick={() => setShowImgModal(false)}>Hide</IonButton>

                    {/* Whisky details */}
                    <div>{currentWhisky.name}
                        : {currentWhisky.distillery} {currentWhisky.smws > '' ? " (" + currentWhisky.smws + ")" : ""}</div>
                    <div>{currentWhisky.region} </div>
                    <div>{currentWhisky.bottler ? currentWhisky.bottler : "Distillery release"}</div>
                    <div>Notes: {currentWhisky.notes} </div>
                    <div>Shelf: {currentWhisky.shelf}&nbsp;-&nbsp;
                        {getStars(currentWhisky.rating)}&nbsp;-&nbsp;{getDollars(currentWhisky.price)} </div>

                    {/* Link to whiskybase */}
                    <div>Search <a target="_whiskybase"
                                   href={"https://www.whiskybase.com/search?q=" + encodeURIComponent(currentWhisky.name)}>whiskybase
                        (external)</a></div>

                    {/* The image */}
                    {currentImg.length > 100 ?
                        <img alt="" height="80%" style={modalImageStyle} src={currentImg}/>
                        : <img alt="" height="80%" style={modalImageStyle} src=""/>
                    }
                </IonModal>


                <IonList>
                    <IonListHeader>
                        <IonLabel color="primary">{whiskies.data.length} Whiskies
                            {hideEmpties ? " (empties shelf hidden)" : ""}
                        </IonLabel>
                    </IonListHeader>


                    {Array.from(whiskies.data.sort((a: any, b: any) => {
                        return a.nameLower > b.nameLower ? 1 : (a.nameLower < b.nameLower ? -1 : 0);
                    }).map((item: any) => itemMatch(item, searchLower) ? addItem(item) : ""))}

                </IonList>
            </IonCard>
        </>
    )
}



