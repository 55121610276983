import React, { useContext } from "react";
import {
    IonItemSliding, IonCard, IonList, IonLabel, IonIcon, IonItemOptions,
    IonItemOption, IonItem, IonListHeader
} from "@ionic/react";
import { trash } from "ionicons/icons";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { useState, useEffect } from "react";
import { subscribeGenericList } from "../../data/dataArrayUtils";
import { saveShelvesList, SHELVES_KEY } from "../../data/ShelfUtils";
import {DataContext} from '../../hooks/DataContext'


export default function ShelfList  (searchText: any)  {
    const [uid, setUid] = useState("");
    const {shelves, setShelves} = useContext(DataContext);

    useEffect(() => {
        if (firebase) {
            firebase.auth().onAuthStateChanged((authUser) => {
                if (authUser) {
                    if (uid !== authUser.uid) {
                        setUid(authUser.uid);
                        console.log("ShelfList  uid changed=" + authUser.uid)
                        subscribeShelfList(authUser.uid);
                    }
                } else {
                    setUid('');
                    console.log("ShelfList  uid changed=''")
                }
            });
        }
    });

    async function subscribeShelfList(uid: string) {
        subscribeGenericList(uid, SHELVES_KEY, setShelves);

    }

    const passthruStyle = {
        opacity: "85%"
    }

    const searchLower = searchText.searchText.toLowerCase();


    function itemMatch(item: string, searchText: string) {
        return searchText.length === 0 || item.toLowerCase()?.indexOf(searchText) >= 0
    }

    function addItem(item: string) {
        return (
            <IonItemSliding key={"sliding-" + item}>
                <IonItemOptions>
                    <IonItemOption color="danger" onClick={() => deleteItem(item)}>
                        <IonIcon slot="icon-only" icon={trash} />
                    </IonItemOption>
                </IonItemOptions>
                <IonItem key={item} onClick={() => console.log("Selected " + item)}>
                    <IonLabel><h3>{item}</h3></IonLabel>
                </IonItem>
            </IonItemSliding>
        )
    }
    function deleteItem(key: string) {
        const i = shelves.list.indexOf(key);
        if (i > -1) {
            shelves.list.splice(i, 1);
            saveShelvesList(uid, shelves.list);
            console.log("DeleteItem " + key);
        }
    }


    return (
        <IonCard style={passthruStyle}>
            <IonList >
                <IonListHeader >
                    <IonLabel color="primary">{shelves.list.length} Shelves</IonLabel>
                </IonListHeader>


                {shelves.list.map((item) => {
                    return itemMatch(item, searchLower) && addItem(item);
                })}

            </IonList>
        </IonCard>
    )
}


