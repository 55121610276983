import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

import React, {createContext, useEffect, useState} from 'react'
import {parseJsonDocToMap, sortMapToList} from '../data/dataMapUtils'
import {initFirebase} from '../firebase/firebase-config'
import {GenericListHolder, GenericMapHolder, subscribeGenericList, subscribeGenericMap} from '../data/dataArrayUtils'
import {BOTTLERS_KEY} from '../data/BottlerUtils'
import {SHELVES_KEY} from '../data/ShelfUtils'
import {WISHLIST_KEY} from '../data/WishlistUtils'
import {WHISKY_KEY} from '../data/WhiskyUtils'

export class Distilleries {
  sortedDistilleryList = [{}];
}
export type Bottlers = GenericListHolder
export type Shelves = GenericListHolder
export type Wishlist = GenericListHolder
export type Whiskies = GenericMapHolder

export type DataContextType = {
    distilleries: Distilleries;
    setDistilleries: React.Dispatch<React.SetStateAction<Distilleries>>;
    bottlers: Bottlers;
    setBottlers: React.Dispatch<React.SetStateAction<Bottlers>>;
    shelves: Shelves,
    setShelves: React.Dispatch<React.SetStateAction<Shelves>>;
    wishlist: Wishlist,
    setWishlist: React.Dispatch<React.SetStateAction<Wishlist>>;
    whiskies: Whiskies,
    setWhiskies: React.Dispatch<React.SetStateAction<Whiskies>>;
    showAddBottler: boolean,
    setShowAddBottler: React.Dispatch<React.SetStateAction<boolean>>;
    showAddDistillery: boolean,
    setShowAddDistillery: React.Dispatch<React.SetStateAction<boolean>>;
    showAddWhisky: string,
    setShowAddWhisky: React.Dispatch<React.SetStateAction<string>>;
}


export const DataContext = createContext<DataContextType>({} as any);

export function DataProvider  (props: {children?: React.ReactNode}) {
    const [sortedDistilleryList, setSortedDistilleryList] = useState(new Distilleries());
    const [uid, setUid] = useState("");
    const [bottlers, setBottlers] = useState(new GenericListHolder());
    const [shelves, setShelves] = useState(new GenericListHolder());
    const [wishlist, setWishlist] = useState(new GenericListHolder());
    const [whiskies, setWhiskies] = useState(new GenericMapHolder());
    const [showAddBottler, setShowAddBottler] = useState(false);
    const [showAddDistillery, setShowAddDistillery] = useState(false);
    const [showAddWhisky, setShowAddWhisky] = useState("");

    initFirebase();

    // Distilleries
    useEffect(() => {
        if (firebase) {
            firebase.auth().onAuthStateChanged((authUser) => {
                if (authUser) {
                    if (uid !== authUser.uid) {
                        setUid(authUser.uid);
                        console.log("distillery context  uid changed=" + authUser.uid)
                        // Distilleries
                        subscribeDistilleryList(authUser.uid);
                        // Bottlers
                        subscribeGenericList(authUser.uid, BOTTLERS_KEY, setBottlers);
                        // Shelves
                        subscribeGenericList(authUser.uid, SHELVES_KEY, setShelves);
                        // Wishlist
                        subscribeGenericList(authUser.uid, WISHLIST_KEY, setWishlist);
                        // Whiskies
                        subscribeGenericMap(authUser.uid, WHISKY_KEY, setWhiskies);

                    }
                } else {
                    setUid('');
                    console.log("distillery context uid changed=''")
                }
            });
        }
    });

    async function subscribeDistilleryList(uid: string) {
        console.log("context subscribe uid=" + uid);
        if (uid !== "") {
            firebase.firestore().doc("users/" + uid + "/distilleries/map").onSnapshot((snap) => {
                const allData = snap.get("distilleryList");
                if (!allData) return;
                const newMap = parseJsonDocToMap(allData);
                const sortedList = sortMapToList(newMap);
                console.log("saving context distillery list")
                const distilleries = new Distilleries();
                distilleries.sortedDistilleryList = sortedList;
                setSortedDistilleryList(distilleries);
            })
        } else {
            console.log("clearing context distillery list")
            setSortedDistilleryList(new Distilleries());
        }

    }

    function getContextValue(): DataContextType   {
        return {
            distilleries: sortedDistilleryList,
            setDistilleries: setSortedDistilleryList,
            bottlers: bottlers,
            setBottlers: setBottlers,
            shelves: shelves,
            setShelves: setShelves,
            wishlist: wishlist,
            setWishlist: setWishlist,
            whiskies: whiskies,
            setWhiskies: setWhiskies,
            showAddBottler: showAddBottler,
            setShowAddBottler: setShowAddBottler,
            showAddDistillery: showAddDistillery,
            setShowAddDistillery: setShowAddDistillery,
            showAddWhisky: showAddWhisky,
            setShowAddWhisky: setShowAddWhisky,
        };
    }

    return (
        <DataContext.Provider value={getContextValue()}>
            {props.children}
        </DataContext.Provider>
    );
}
