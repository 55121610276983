export const DefaultBottlers = [
  "Abbeyhill Distilling Co. Ltd.",
  "Adelphi Whisky Limited",
  "Ainslie & Heilbron Distillers Ltd.",
  "Alexander Dunn & Co Ltd.",
  "Alexander Macdonald & Co Ltd.",
  "Alfred Dunhill Scotch Whisky Ltd.",
  "Anstie Distillers Ltd.",
  "Averys of Bristol Ltd.",
  "Berry Bros & Rudd Ltd.",
  "Bulloch Lade & Co. Ltd. Blenders",
  "Burn Stewart Distillers Ltd.",
  "Campbell Distillers Ltd.",
  "Charles Mackinlay & Co. Ltd.",
  "Chivas Brothers Ltd.",
  "Clydesdale Scotch Whisky Co. Ltd.",
  "Cockburn & Campbell Ltd.",
  "Corney & Barrow Ltd.",
  "Distillers Agency Ltd.",
  "Donald Fisher Ltd.",
  "Douglas Laing & Co. Ltd.",
  "Douglas MacNiven & Co. Ltd.",
  "Eaglesome Ltd.",
  "Eldridge Pope & Co. plc",
  "Ewen & Co. Ltd.",
  "Findlater Mackie Todd and Co. Ltd.",
  "First Blending Company",
  "George Ballantine & Son Ltd.",
  "Glen Catrine Bonded Warehouse Ltd.",
  "Glen Rossie Distillers Ltd.",
  "Glenfyne Distillery Co. Ltd.",
  "Gordon Graham & Co.",
  "H. Stoddart and Taylor",
  "Halewood International Ltd.",
  "Hall & Bramley Ltd.",
  "Hamish Robertson & Co.",
  "Hankey Bannister & Co.",
  "Hart Brothers Ltd.",
  "Harvie's of Edinburgh Ltd.",
  "Hill, Thomson & Co. Ltd.",
  "Hiram Walker & Sons, Ltd.",
  "Ian Macleod & Co.",
  "International Whisky Company Ltd.",
  "Inver House Distillers",
  "J. W. Hardie Ltd.",
  "James Buchanan & Co. Ltd.",
  "James Catto & Co. Ltd.",
  "James Macarthur & Co. Ltd.",
  "James Turnbull Exports Ltd.",
  "James Watson & Co.",
  "Jas. & Geo. Stodart Ltd.",
  "John & Robert Harvey & Co. Ltd.",
  "John Begg Ltd.",
  "John Crabbie & Co. Ltd.",
  "John E. McPherson and Sons Ltd.",
  "John Gillon & Co. Ltd.",
  "John Haig & Co. Ltd.",
  "John Hopkins & Co. Ltd.",
  "Justerini & Brooks Ltd.",
  "Kinross Whisky Co. Ltd.",
  "Langs Brothers Ltd.",
  "Leith Distillers Ltd.",
  "Lombard Brands Ltd.",
  "London & Scottish International Ltd.",
  "Long John Distilleries Ltd.",
  "M. J. Dowdeswell & Co. Ltd.",
  "MacDonald Greenlees Ltd.",
  "Macduff International Ltd.",
  "Marshall Taplow Ltd.",
  "Matthew Gloag & Sons Ltd.",
  "Montrose Whisky Co. Ltd.",
  "Morrison Bowmore Distillers Ltd.",
  "N.M.H. Holdings Ltd.",
  "Old St. Andrews",
  "Pattisons Ltd.",
  "Phillips Newman & Co. Ltd.",
  "Praban na Linne Ltd.",
  "Premier Scotch Whisky Co.",
  "Quality Spirits International",
  "Signatory Vintage Scotch Whisky Co. Ltd.",
  "Stewart & Son of Dundee Ltd.",
  "The Beltane Whisky Co.",
  "The Bottlers",
  "The Charles House Dist.",
  "The Glen Ness Distilling Co. Ltd",
  "The Vintage Malt Whisky Co.",
  "Whyte and Mackay Ltd",
  "William Grant & Sons Ltd.",
  "William Lawson Distillers Ltd.",
  "William Lundie & Co. Ltd.",
  "William Maxwell Ltd.",
  "William Teacher & Sons Ltd.",
  "Wilson & Morgan Ltd.",
  "Cadenhead's (Wm Cadenhead's Ltd.)",
  "Woodside Wylie & Co.",
  "SMWS (The Scotch Malt Whisky Society)",
  "Gordon and MacPhail",
  "Maltbarn",
  "That Boutique-y Whisky Company",
];
