import {
    IonItemSliding, IonCard, IonList, IonLabel, IonIcon, IonItemOptions,
    IonItemOption, IonItem, IonListHeader
} from "@ionic/react";
import { trash } from "ionicons/icons";
import { useContext } from "react";
import { VirtualScrollChild } from "../../components/VirtualScrollChild";
import { removeDistillery } from "../../data/DistilleryUtils";
import { DataContext } from "../../hooks/DataContext";
import { UidContext } from "../../hooks/UserDetailsContexts";

// TODO: Favorites?

const DistilleryList = (searchText: any) => {
    /* Import UID & Distilleries from conntext */
    const [uid] = useContext(UidContext);
    const {distilleries} = useContext(DataContext);

    const passthruStyle = {
        opacity: "85%"
    }



    /* Filter toLower */
    const searchLower = searchText.searchText.toLowerCase();

    return (
        <IonCard style={passthruStyle}>
            <IonList>
                <IonListHeader >
                    <IonLabel color="primary">{distilleries.sortedDistilleryList.length} Distilleries</IonLabel>
                </IonListHeader>

                { /* Filter the list and display it */}
                {distilleries.sortedDistilleryList.map((item) => {
                    return itemMatch(item, searchLower) && addItem(item);
                })}

            </IonList>
        </IonCard>
    );

    /* Filter function, search on name, retion and smws id */
    function itemMatch(item: any, searchText: string) {
        return searchText.length === 0 || item.nameLower?.indexOf(searchText) >= 0
            || item.regionLower?.indexOf(searchText) >= 0
            || item.smwsLower?.indexOf(searchText) >= 0;
    }

    function addItem(item: any) {
        return (
            <VirtualScrollChild key={"vs" + item.uuid} height={item.smws > '' ? 90 : 70}>
                <IonItemSliding key={"slider" + item.uuid}>
                    <IonItemOptions>
                        <IonItemOption color="danger" onClick={() => deleteItem(item.name)}>
                            <IonIcon slot="icon-only" icon={trash} />
                        </IonItemOption>
                    </IonItemOptions>
                    <IonItem key={item.uuid} onClick={() => console.log("Selected " + item.name)}>
                        <IonLabel>
                            <h2>{item.name}</h2>
                            <p>{item.region}</p>
                            <p>{item.smws > "" ? "SMWS: " + item.smws : ""}</p>
                        </IonLabel>
                    </IonItem>
                </IonItemSliding>
            </VirtualScrollChild>
        )
    }
    function deleteItem(name: string) {
        console.log("DeleteItem " + name);
        removeDistillery(uid, name);

    }
}

export default DistilleryList;


