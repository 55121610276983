import { createGenericListDetaults, loadGenericList, saveGenericList } from "./dataArrayUtils";
import { DefaultBottlers } from "./DefaultBottlers";

export const bottlersVersion = 1;
export const BOTTLERS_KEY = 'bottlers';

export async function createDefaultBottlers(uid: string) {
    createGenericListDetaults(uid, BOTTLERS_KEY, bottlersVersion, DefaultBottlers);
}

export function saveBottlersList(uid: string, bottlerList: string[]) {
    saveGenericList(uid, BOTTLERS_KEY, bottlerList);
}

export async function loadBottlersList(uid: string): Promise<string[]> {
    return loadGenericList(uid, BOTTLERS_KEY);
}

