import { useState, useContext } from "react";
import { IonInput, IonButton, IonFab, IonFabButton, IonCard, IonCardContent, IonIcon, IonToast } from "@ionic/react";
import { add } from 'ionicons/icons';
import { WISHLIST_KEY } from "../../data/WishlistUtils";
import { createGenericListEntry } from "../../data/dataArrayUtils";
import { UidContext } from "../../hooks/UserDetailsContexts";
import {DataContext} from '../../hooks/DataContext'


export default function AddWishlist () {

    const [showAdd, setShowAdd] = useState(false);
    const [uid] = useContext(UidContext)
    const [name, setName] = useState('');
    const [showErrorToast, setShowErrorToast] = useState('');
    const {wishlist} = useContext(DataContext);

    const saveWishlist = () => {
        try {
            createGenericListEntry(uid, WISHLIST_KEY, wishlist, name);
            setName('');
            setShowAdd(false);
        }
        catch (e : any) {
            console.log("error saving Wishlist: " + e)
            setShowErrorToast(e);
        }
    }


    function AddItemForm() {
        return (
            <IonCard>
                <IonCardContent>
                    <IonInput clearInput={true} name="name" value={name} placeholder="Name" onIonChange={e => setName(e.detail.value!)}></IonInput>
                    <IonButton onClick={saveWishlist}>Save</IonButton>
                    <IonButton color="light" onClick={() => setShowAdd(false)}>Cancel</IonButton>
                </IonCardContent>
            </IonCard>

        )
    }


    return (
        <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={() => setShowAdd(true)}>
                    <IonIcon icon={add}></IonIcon>
                </IonFabButton>
            </IonFab>
            <IonToast isOpen={showErrorToast > ''}
                      onDidDismiss={() => setShowErrorToast('')}
                      message={showErrorToast}
                      duration={1500}
                      position="bottom"
                      color="warning"
            />
            {showAdd && <AddItemForm/>}
        </>

    )
}

