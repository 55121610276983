import { reviver } from "./dataArrayUtils";

/**
 * Sort a map and return an array of its names in sorted order.  Sorted by element "nameLower" 
 * or alternatively "name" which will be connverted to lower to sorting alphabetically.
 * 
 * @param orig The map with the data to sort
 * @returns An array of the sorted names
 */
export function sortMapToList(orig: Map<string, any>): any[] {
    const list2 = Array.from(orig.values()).sort((a, b) => {
        if (a.nameLower && b.nameLower)
            return a.nameLower > b.nameLower ? 1 : (a.nameLower < b.nameLower ? -1 : 0);
        const aLower = a.name.toLowerCase();
        const bLower = b.name.toLowerCase();
        return aLower > bLower ? 1 : (aLower < bLower ? -1 : 0);

    });
    return list2;
}


export function parseJsonDocToMap(doc: string): Map<string, {}> {
    return JSON.parse(doc, reviver);
}

