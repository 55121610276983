import { useContext, useState } from "react";
import { IonInput, IonButton, IonFab, IonFabButton, IonCard, IonCardContent, IonIcon, IonToast } from "@ionic/react";
import { add } from 'ionicons/icons';
import { SHELVES_KEY } from "../../data/ShelfUtils";
import { UidContext } from "../../hooks/UserDetailsContexts";
import { createGenericListEntry } from "../../data/dataArrayUtils";
import {DataContext} from '../../hooks/DataContext'


export default function AddShelf () {
    const [showAdd, setShowAdd] = useState(false);
    const [uid] = useContext(UidContext)
    const [name, setName] = useState('');
    const [showErrorToast, setShowErrorToast] = useState('');
    const {shelves} = useContext(DataContext);

    const saveShelf = () => {
  
        try {
            createGenericListEntry(uid, SHELVES_KEY, shelves, name);
            setName('');
            setShowAdd(false);
        }
        catch (e : any) {
            setShowErrorToast(e);
            console.log("error saving Shelf: " + e)
        }
    }


    function AddShelfForm() {
        return (
            <IonCard>
                <IonCardContent>
                    <IonInput clearInput={true} name="name" value={name} placeholder="Name" onIonChange={e => setName(e.detail.value!)}></IonInput>
                    <IonButton onClick={saveShelf}>Save</IonButton>
                    <IonButton color="light" onClick={() => setShowAdd(false)}>Cancel</IonButton>
                </IonCardContent>
            </IonCard>

        )
    }
    return (
        <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={() => setShowAdd(true)}>
                    <IonIcon icon={add}></IonIcon>
                </IonFabButton>
            </IonFab>
            <IonToast isOpen={showErrorToast > ''}
                onDidDismiss={() => setShowErrorToast('')}
                message={showErrorToast}
                duration={1500}
                position="bottom"
                color="warning"
            />

            {showAdd && <AddShelfForm/>}
        </>

    )

}


