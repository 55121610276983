import React, { useState, useContext } from 'react';

import {
  IonContent, IonHeader, IonPage, IonToolbar, IonSearchbar,
  IonMenuButton, IonButtons, IonButton, IonFab, IonFabButton, IonIcon
} from '@ionic/react';
import './Whiskies.css';
import WhiskyList from './WhiskyList';
import { add } from 'ionicons/icons';
import { UidContext } from '../../hooks/UserDetailsContexts';
import {DataContext} from '../../hooks/DataContext'

export default function Whiskies() {
  const [searchText, setSearchText] = useState('');
  const [uid] = useContext(UidContext);
  const {setShowAddWhisky} = useContext(DataContext);


  function doWhiskies(searchText: string) {
    return (
        <>
          <WhiskyList searchText={searchText}/>
        </>
    );
  }


  return (
      <IonPage>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            Whiskies
          </IonToolbar>
          {uid > "" && (
              <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                            placeholder="Search by any whisky data ... try it" showClearButton="always"
                            showCancelButton="never"></IonSearchbar>
          )}
        </IonHeader>

        <IonContent class="whiskies">

          {/* Link to edit form, clear uuid of whisky, no edit! */}
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/whiskyedit" onClick={() => {
              setShowAddWhisky("");
            }}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>

          {uid > "" ? doWhiskies(searchText)
              : <IonButton routerLink="/login">Login</IonButton>
          }
        </IonContent>


      </IonPage>
  )
}


