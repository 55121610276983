import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import 'firebase/compat/database'
import { replacer } from "./dataArrayUtils";
import { parseJsonDocToMap } from "./dataMapUtils";
import { DefaultDistilleries } from "./defaultDistilleries";
const { v4: uuidv4 } = require('uuid');

export async function createDefaultDistilleries(uid: string) {
    if (uid === '') return;

    const user = firebase.firestore().doc("users/" + uid);
    const doc = await user.get();
    if (doc.data()?.distilleriesVersion! !== DefaultDistilleries.version) {
        console.log("Initialising distilleries");
        const distilleryList = new Map();
        DefaultDistilleries.distilleries.forEach((data) => {
            //console.log("Adding " + data.Distillery);
            distilleryList.set(data.Distillery,
                {
                    name: data.Distillery,
                    region: data.Region,
                    smws: data.SMWS,
                    nameLower: data.Distillery.toLowerCase(),
                    regionLower: data.Region.toLowerCase(),
                    smwsLower: data.SMWS.toLowerCase(),
                    uuid: uuidv4()
                });
        });
        saveDistilleriesList(uid, distilleryList);

        // Record version update
        user.set({
            distilleriesVersion: DefaultDistilleries.version
        }, { merge: true });
    }
}

export function saveDistilleriesList(uid: string, distilleryList: Map<string, {}>) {
    console.log("Saving distillery list: " + distilleryList.size);
    const collection = firebase.firestore().collection("users/" + uid + "/distilleries");
    const newDoc = collection.doc("map");
    const body = JSON.stringify(distilleryList, replacer);
    newDoc.set({ distilleryList: body }, { merge: true });
    console.log("Distillery list saved: " + distilleryList.size);
}

export async function loadDistilleriesList(uid: string) : Promise<Map<string, {}>>  {
    console.log("Loading distillery list");
    const doc = firebase.firestore().doc("users/" + uid + "/distilleries/map");
    const docData = await doc.get();
    const data = docData.data();

    const res = parseJsonDocToMap(data?.distilleryList);
    console.log("Loaded distilliry list: " + res.size);
    return res;
}

export async function removeDistillery(uid : string, name: string) {
    const old = loadDistilleriesList(uid);
    const newMap = await old;

    newMap.delete(name);
    saveDistilleriesList(uid, newMap);

}
