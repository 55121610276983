import { useContext } from "react";
import { IonPage, IonHeader, IonToolbar, IonContent, IonButton, IonCard, IonCardTitle, IonCardContent, IonButtons, IonMenuButton, IonModal, IonText, IonInput, IonToast } from "@ionic/react";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { useState, useEffect } from "react";
import { StyledFirebaseAuth } from "react-firebaseui";
import { loginUiConfig } from "../../firebase/login-config";
import './Login.css'
import { UidContext } from "../../hooks/UserDetailsContexts";
import {DataContext} from '../../hooks/DataContext'

/* Use the google firebase login ui */
export default function Login  ()  {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const {whiskies, wishlist} = useContext(DataContext);
    const [uid, setUid] = useContext(UidContext);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [toastText, setToastText] = useState('');

    const passthruStyle = {
        opacity: "85%"
    }

    function deleteUser(uid: string) {
        if (uid === "") return;
        if (confirmationText !== "delete") {
            setToastText("Delete verification does not match");
            return;
        }
        const doc = firebase.firestore().doc("/users/" + uid);
        console.log("Delete doc: " + doc.path);
        doc.delete();
        console.log("Delete user: " + uid);
        const user = firebase.auth().currentUser;
        user?.delete().then(() => {
            console.log("User deleted");
            setUid("");
            firebase.auth().signOut();
            setShowDeleteConfirm(false);
            setToastText("User deleted");
        }).catch((e) => {
            console.log("Error deleting user: " + e);
            setShowDeleteConfirm(false);
            setToastText(e);
        });

    }

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!isSignedIn) {
        return (
            <IonPage >
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        Account
                    </IonToolbar>
                </IonHeader>
                <IonContent class="login">
                    <IonToast isOpen={toastText > ""} duration={2000}
                        onDidDismiss={() => setToastText("")}
                        color="warning"
                        position="middle" message={toastText} />

                    <IonCard>
                        <IonCardContent>
                            <StyledFirebaseAuth uiConfig={loginUiConfig} firebaseAuth={firebase.auth()} />

                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        )
    } else {
        return (
            <IonPage className="login">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        Account
                    </IonToolbar>
                </IonHeader>
                <IonContent class="login">
                    <IonToast isOpen={toastText > ""} duration={2000}
                        onDidDismiss={() => setToastText("")}
                        color="warning"
                        position="middle" message={toastText} />
                    <IonCard style={passthruStyle}>
                        <IonCardTitle>Welcome {firebase.auth().currentUser?.displayName}! You are now signed-in!</IonCardTitle>
                        <IonCardContent>
                            <h3>Email: {firebase.auth().currentUser?.email}</h3>
                            <ul>
                                <li>You have {whiskies.data.length} whiskes in your collection.</li>
                                <li>You have {wishlist.list.length} whiskes on your wishlist.</li>
                            </ul>
                            <IonButton onClick={() => firebase.auth().signOut()}>Sign-out</IonButton>
                        </IonCardContent>
                    </IonCard>
                    <IonCard style={passthruStyle}>
                        <IonCardContent>
                            <IonButton size="small" color="danger" onClick={() => { setShowDeleteConfirm(true) }}>Delete Account</IonButton>
                        </IonCardContent>
                    </IonCard>
                    <IonModal isOpen={showDeleteConfirm}>
                        <IonCardContent>
                            <h2><IonText color="danger">Delete my account</IonText></h2>
                            Are you sure you want to delete your account and all your data?
                            Once your account is deleted your data cannot be recovered.
                            <div><IonButton slot="center" color="primary" onClick={() => { setShowDeleteConfirm(false) }}>Keep my account</IonButton></div>
                            <hr />
                            <hr />
                            <div>
                                Please enter "delete" in the text box and press the button to remove your account.
                                <IonInput placeholder="verification"
                                    onIonChange={(e) => { setConfirmationText(e.detail.value!) }}></IonInput>
                                <IonButton size="small" slot="center" color="danger" onClick={() => { deleteUser(uid) }}>Delete my account</IonButton></div>
                        </IonCardContent>
                    </IonModal>
                </IonContent>
            </IonPage>
        )
    }
}