export class DefaultDistilleries {
  static version = 1;
  static regions: any[] = [
    "Austria",
    "Belgium",
    "Bhutan",
    "Bourbon",
    "Brazil",
    "Canada",
    "China",
    "Czech Republic",
    "Denmark",
    "Finland",
    "France",
    "Germany",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Kentucky",
    "Kenya",
    "Liechtenstein",
    "Luxembourg",
    "Mexico",
    "Netherlands",
    "Netherlands Antilles",
    "New Zealand",
    "Norway",
    "Pakistan",
    "Philippines",
    "Poland",
    "Russian Federation",
    "Scotland",
    "Scotland, Campbeltown",
    "Scotland, Highland",
    "Scotland, Islay",
    "Scotland, Islands",
    "Scotland, Lowland",
    "Scotland, Speyside",
    "Serbia And Montenegro",
    "Slovakia",
    "South Africa",
    "Spain",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "United States",
  ];
  
  static distilleries: any[] = [
    {
      "Distillery": "'t Koelschip",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "10th Mountain",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "2bar Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "3 Badge Beverage",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "3 Howls Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "45th Parallel",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1512&DistilleryName=45th+Parallel+Spirits+LLC",
      "SMWS": ""
    },
    {
      "Distillery": "66 Gilead Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "A. Smith Bowman",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Aberfeldy",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=60&DistilleryName=Aberfeldy+Distillery",
      "SMWS": "60"
    },
    {
      "Distillery": "Aberlour",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=54&DistilleryName=Aberlour+Distillery",
      "SMWS": "54"
    },
    {
      "Distillery": "Abhainn Dearg",
      "Region": "Scotland, Isles",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Adirondack Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Adnams",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Aecht Schlenkerla",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Agárdi Pálinkafőzde",
      "Region": "Hungary",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Agder Brenneri",
      "Region": "Norway",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1489&DistilleryName=Agder+Brenneri",
      "SMWS": ""
    },
    {
      "Distillery": "Aichinger",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ailsa Bay",
      "Region": "Scotland, Lowland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1544&DistilleryName=Ailsa+Bay+Distillery",
      "SMWS": ""
    },
    {
      "Distillery": "Aioi Unibio Co. Ltd.",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Akkeshi Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alambik",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alaska Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Albany Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alberta Distillers Ltd.",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alde Gott",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alley 6 Craft Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Allgäu-Brennerei Günther GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Allt-a-Bhainne",
      "Region": "Scotland, Speyside",
      "URL": "",
      "SMWS": "108"
    },
    {
      "Distillery": "Alpenwhisky-Destillerie",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alpirsbacher Klosterbräu",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alt Enderle",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alte Brennerei",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Alte Hausbrennerei A. Wecklein",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Altore",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Amador Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Amrut",
      "Region": "India",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1039&DistilleryName=Amrut+Distilleries+P+Ltd%2E",
      "SMWS": ""
    },
    {
      "Distillery": "An Cnoc",
      "Region": "Scotland, Highland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Anchor Distilling Company",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1147&DistilleryName=Anchor+Distillery",
      "SMWS": ""
    },
    {
      "Distillery": "Annandale",
      "Region": "Scotland, Lowland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Arbikie",
      "Region": "Scotland, Highland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Archie Rose Distilling Co.",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Arcus",
      "Region": "Norway",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ardbeg",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=33&DistilleryName=Ardbeg+Distillery",
      "SMWS": "33"
    },
    {
      "Distillery": "Ardenistile",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ardmore",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=619&DistilleryName=Ardmore+Distillery",
      "SMWS": "66"
    },
    {
      "Distillery": "Ardnahoe Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ardnamurchan",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Arizona High Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Arnsteiner Brauerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Arran",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=500&DistilleryName=Isle+of+Arran+Distillery",
      "SMWS": "121"
    },
    {
      "Distillery": "Asaka",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ascendant Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Asheville Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Astidama",
      "Region": "Indonesia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "ASW",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Auchentoshan",
      "Region": "Scotland, Lowland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=5&DistilleryName=Auchentoshan+Distillery",
      "SMWS": "5"
    },
    {
      "Distillery": "Auchnagie",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Auchroisk",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=95&DistilleryName=Auchroisk+%28Singleton%29+Distillery",
      "SMWS": "95"
    },
    {
      "Distillery": "Augustus Rex",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Aultmore",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=73&DistilleryName=Aultmore+Distillery"
      ,
      "SMWS": "73"
    },
    {
      "Distillery": "Aurora Spirit",
      "Region": "Norway",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Authentic Seacoast Distilling Co. Ltd.",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Avadis Distillery",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "bachgau-Destille",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bainbridge",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bakery Hill Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Balblair",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=70&DistilleryName=Balblair+Distillery"
      ,
      "SMWS": "70"
    },
    {
      "Distillery": "Balcones Distilling",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1545&DistilleryName=Balcones+Distilling+Inc%2E"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Ballast Point",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ballindalloch",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Balmenach",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=48&DistilleryName=Balmenach+Distillery"
      ,
      "SMWS": "48"
    },
    {
      "Distillery": "Balvenie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=40&DistilleryName=Balvenie+Distillery"
      ,
      "SMWS": "40"
    },
    {
      "Distillery": "Bandon Distillery",
      "Region": "Ireland",
      "URL": ""
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Banff",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "67"
    },
    {
      "Distillery": "Bardstown Bourbon Company",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=879&DistilleryName=Bardstown+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Barrel House Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1560&DistilleryName=Barrel+House+Distilling+Company"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Barrell Craft Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Barton 1792 Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1059&DistilleryName=Barton+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Basilico France",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Békési Manufaktúra",
      "Region": "Hungary",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Belgrove",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bellerhof-Brennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Belmont Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Belmont Farm Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ben Nevis",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=78&DistilleryName=Ben+Nevis+Distillery"
      ,
      "SMWS": "78"
    },
    {
      "Distillery": "Ben Wyvis",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=202&DistilleryName=Ben+Wyvis+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Ben Wyvis - Ferintosh",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=202&DistilleryName=Ben+Wyvis+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Benachie Distillery",
      "Region": "Scotland, Speyside",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bendistillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "BenRiach",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=12&DistilleryName=Benriach+Distillery"
      ,
      "SMWS": "12"
    },
    {
      "Distillery": "Benrinnes",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=36&DistilleryName=Benrinnes+Distillery"
      ,
      "SMWS": "36"
    },
    {
      "Distillery": "Benromach",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=47&DistilleryName=Benromach+Distillery"
      ,
      "SMWS": "47"
    },
    {
      "Distillery": "Berkshire Mountain Distillers",
      "Region": "United States",
      "URL": ""
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Bernheim",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bertrand",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "BFLO Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bières Michard",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bieri Brennerei Bauernhof",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Big Bottom Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bimber Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Birgitta Rust - Piekfeine Brände e.K.",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Birkenhof-Brennerei GmbH",
      "Region": "Germany",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=878&DistilleryName=Birkenhof+Brennerei"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Black Button Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Black Canyon Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Black Dirt Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Black Gate Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Black Heron Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bladnoch",
      "Region": "Scotland, Lowland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=50&DistilleryName=Bladnoch+Distillery"
      ,
      "SMWS": "50"
    },
    {
      "Distillery": "Blair Athol",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=68&DistilleryName=Blair+Athol+Distillery"
      ,
      "SMWS": "68"
    },
    {
      "Distillery": "Blaue Maus",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Blaum Bros. Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Blue Ridge Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Blue Spirits Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Boann Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Böckl",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Boldizar",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bold’s Schnapsideen",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bone Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Boone County Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bosch-Edelbrand",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Böttchehof",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bow Street Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bowmore",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=3&DistilleryName=Bowmore+Distillery"
      ,
      "SMWS": "3"
    },
    {
      "Distillery": "Box",
      "Region": "Sweden",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1484&DistilleryName=Box+Destilleri"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Braeckman Distillers",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Braeval",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=203&DistilleryName=Braeval+Distillery"
      ,
      "SMWS": "113"
    },
    {
      "Distillery": "Branger",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brasserie de Bercloux",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Aare Bier - Old River",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Baumgartner Tiroler Bier",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Falken AG",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Locher",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Monstein",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauerei Unser Bier",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Braugasthof Papiermühle",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brauhaus am Lohberg zu Wismar",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Braunstein",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Breckenridge Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brenne",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei & Mosterei Stadelmann",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Druffel",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei E. & R. Brasch",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Eiger",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Feller",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Grogg",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Gruel",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Henrich",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Höhler",
      "Region": "Germany",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1387&DistilleryName=Brennerei+H%F6hler"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Kramer",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei L. Psenner",
      "Region": "Italy",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Lanz",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Ludwig Faber",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Martin Meier",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Mühlenthal",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Roder",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Schwab",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Volker Theurer im Gasthof Lamm",
      "Region": "Germany",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1292&DistilleryName=Brennerei+Volker+Theurer"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Wild",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Ziegler",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brennerei Zürcher",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Breuckelen",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1559&DistilleryName=Breuckelen+Distilling"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Brickway Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Broad Branch Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1445&DistilleryName=Broad+Ford+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Broadbent",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Broger",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brora",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=61&DistilleryName=Brora+Distillery"
      ,
      "SMWS": "61"
    },
    {
      "Distillery": "Brouwerij de Molen",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brouwerij Wilderen",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brouwerij-Stokerij PIRLOT",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brown-Forman Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bruckners Erz Bräu",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bruichladdich and Port Charlotte",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=23&DistilleryName=Bruichladdich+Distillery"
      ,
      "SMWS": "23, 127"
    },
    {
      "Distillery": "Brûlerie du Revermont",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Brunschwiler Brennerei",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Buffalo Trace Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1004&DistilleryName=Buffalo+Trace+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Bull Run Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bulleit",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bully Boy Distillers",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=10&DistilleryName=Bunnahabhain+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Bunnahabhain",
      "Region": "Scotland, Islay",
      "URL": "",
      "SMWS": "10"
    },
    {
      "Distillery": "Burgdorfer",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Burger Hofbrennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Bushmills",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2007&DistilleryName=Bushmills+Distillery"
      ,
      "SMWS": "51"
    },
    {
      "Distillery": "C. W. Falckenthal Söhne",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cadushy",
      "Region": "Netherlands Antilles",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Caldera Distilling",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Caledonian",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=155&DistilleryName=Caledonian+Distillery"
      ,
      "SMWS": "G3"
    },
    {
      "Distillery": "Calvados Pierre HUET",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cambus",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=149&DistilleryName=Cambus+Distillery"
      ,
      "SMWS": "G8"
    },
    {
      "Distillery": "Cameronbridge",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "G4"
    },
    {
      "Distillery": "Camp 1805",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Canadian Mist",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Caol Ila",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=53&DistilleryName=Caol+Ila+Distillery"
      ,
      "SMWS": "53"
    },
    {
      "Distillery": "Caperdonich",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=38&DistilleryName=Caperdonich+Distillery"
      ,
      "SMWS": "38"
    },
    {
      "Distillery": "Carbon Glacier Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cardhu",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=106&DistilleryName=Cardhu+Distillery"
      ,
      "SMWS": "106"
    },
    {
      "Distillery": "Cardrona Distillery",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Carsebridge",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "G2"
    },
    {
      "Distillery": "Cascade Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cascade Peak Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cathead Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Catoctin Creek Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Catskill Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cave de la Crausaz",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cedar Ridge Winery & Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Central City Brewers + Distillers",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Central Standard Craft Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Charbay Distillery & Winery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Charles Medley Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Chase Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Chichibu",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1600&DistilleryName=Chichibu+Distillery"
      ,
      "SMWS": "130"
    },
    {
      "Distillery": "Chicken Cock Distilleries",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Chicken Hill",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Chita",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1610&DistilleryName=Chita+Distillery"
      ,
      "SMWS": "G13"
    },
    {
      "Distillery": "Chugoku Jozo",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Clear Creek Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cleveland Whiskey",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Clynelish",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=26&DistilleryName=Clynelish+Distillery"
      ,
      "SMWS": "26"
    },
    {
      "Distillery": "Coillmór",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Coleburn",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "56"
    },
    {
      "Distillery": "Collier and McKeel",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Colorado Gold Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Conecuh Ridge Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Connacht Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Convalmore",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "83"
    },
    {
      "Distillery": "Cooley Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2000&DistilleryName=Cooley+Distillery"
      ,
      "SMWS": "117, 118"
    },
    {
      "Distillery": "Cooperstown Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Copper Fox Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Copper Run Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Coppersea Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Copperworks Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Corby Spirit and Wine Ltd.",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cornelius Pass Roadhouse Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Corry (Broadford)",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Corsair Artisan Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1540&DistilleryName=Corsair+Artisan+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Cotswolds Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cradle Mountain",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Craft Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cragganmore",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=37&DistilleryName=Cragganmore+Distillery"
      ,
      "SMWS": "37"
    },
    {
      "Distillery": "Craigellachie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=44&DistilleryName=Craigellachie+Distillery"
      ,
      "SMWS": "44"
    },
    {
      "Distillery": "Crooked Water Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Cut Spike Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dà Mhìle Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dachstein Destillerie Mandlberggut",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Daftmill",
      "Region": "Scotland, Lowland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1383&DistilleryName=Daftmill+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Daill",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dailuaine",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=41&DistilleryName=Dailuaine+Distillery"
      ,
      "SMWS": "41"
    },
    {
      "Distillery": "Dakota Spirits Distillery L.L.C.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dalaruan Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dallas Dhu",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=45&DistilleryName=Dallas+Dhu+Distillery"
      ,
      "SMWS": "45"
    },
    {
      "Distillery": "Dallas Distilleries Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dalmore",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=13&DistilleryName=Dalmore+Distillery"
      ,
      "SMWS": "13"
    },
    {
      "Distillery": "Dalmunach",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dalwhinnie",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=102&DistilleryName=Dalwhinnie+Distillery"
      ,
      "SMWS": "102"
    },
    {
      "Distillery": "Dancing Pines Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dark Corner Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dark Horse",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dartmoor Whisky Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "De Graal",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "De Schans",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "De Tweekoppige Phoenix",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Deanston",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=79&DistilleryName=Deanston+Distillery"
      ,
      "SMWS": "79"
    },
    {
      "Distillery": "Death's Door Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Deerhammer",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1579&DistilleryName=Deerhammer+Distilling+Company"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Deheck",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Delaware Phoenix Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Demmel & Cie.",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destileria Limtuaco & Co., Inc.",
      "Region": "Philippines",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destilerías Aldea, S.L.",
      "Region": "Spain",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1492&DistilleryName=Destilerias+Liber+S%2EL%2E"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Destilerías Y Crianza",
      "Region": "Spain",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2057&DistilleryName=Destilerias+Y+Crianza+del+Whisky%2C+S%2EA%2E"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Destillate Siegfried Herzog",
      "Region": "Austria",
      "URL": ""
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Destille Kaltenthaler",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie & Genussmanufaktur Rieger & Hofmeister",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie Egnach",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie Günter Busch",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie Ralf Hauer",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie Rogner",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destillerie Wilhelm Behr",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Destylarnia Piasecki",
      "Region": "Poland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Det Norske Brenneri",
      "Region": "Norway",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Detroit City Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Die Kleinbrennerei Rackelmann",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dietrich",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dillon's Small Batch Distillers",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distilleerderij Keuris",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Castan",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Claeyssens de Wambrechies",
      "Region": "France",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1319&DistilleryName=Distillerie+Claeyssens+de+Wambrechies"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie de Monsieur Balthazar",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie de Paris",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie des Menhirs",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Diedenacker",
      "Region": "Luxembourg",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Dreum",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie du Pays d'Othe",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie du Périgord",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Ergaster",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie G. Miclo",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Grallet-Dupic",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Janot",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie La Rouget de L'Isle",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Mavela",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Meyer",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Moutard",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Muller-Lemmer",
      "Region": "Luxembourg",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Quintessence",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Seetal GmbH",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillerie Theo Preiss",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Distillery 291",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Do Good Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dobson's",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dolleruper Destille",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Domaine de Bourjac",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Domaine des Hautes Glaces",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Don Quixote Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Donner-Peltier Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Door County Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dornoch Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Downslope Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Doz de Dauzanges",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Drentsche Schans",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Drexler",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dry County Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Dry Fly Distilling",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1516&DistilleryName=Dry+Fly+Distilling"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Dufftown",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=91&DistilleryName=Dufftown+Distillery"
      ,
      "SMWS": "91"
    },
    {
      "Distillery": "Dumbarton",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "G14"
    },
    {
      "Distillery": "DÜRR Edelbranntweine GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Duvel Moortgat",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eaglesburn Distillery",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eastside Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Echlinville Distillery Ltd",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelbrände Senft",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelbrennerei Anton Bischof",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelbrennerei Dirker",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelbrennerei Haas",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelbrennerei Schloss Neuenburg",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edelobst & Whiskydestille Höning",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eden Mill",
      "Region": "Scotland",
      "URL": "",
      SMWS: ""
    },
    {
      "Distillery": "Edgefield Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Edradour",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=32&DistilleryName=Edradour+Distillery"
      ,
      "SMWS": "32"
    },
    {
      "Distillery": "Eifel Destillate",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eigashima Shuzo",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eimverk Distillery",
      "Region": "Iceland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eleven Wells Spirit",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ellensburg Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Emperador Distillers, Inc.",
      "Region": "Philippines",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Enghaven",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "English Spirit Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Erismann",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Erlebnisbrennerei Kallnach",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Etter",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Eylandt Legend",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ezra Cox Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fary Lochan",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Feinbrennerei Simon's",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Feiner Kappler",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Feisty Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fettercairn",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=94&DistilleryName=Fettercairn+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "FEW Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": "B4"
    },
    {
      "Distillery": "Filibuster Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Finch Whiskydestillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Finger Lakes Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fisselier",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fleurieu Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Florida Caribbean Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Florida Farm Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fogs's End Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Foothills Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Forschungs- und Lehrbrennerei der UNI Hohenheim",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Forty Creek",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Four Roses Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1073&DistilleryName=Four+Roses+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Franken Bräu Riedbach",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Frankford Distillery Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Franz Kostenzer",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fremont Mischief Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fuji Gotemba",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Fukano Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gammelstilla Whisky AB",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Garnheath",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Garrison Brothers Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gartbreck Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gasthausbrauerei Wismar GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gasthof Hirsch",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gelephu Distillery",
      "Region": "Bhutan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gemenc Distillery",
      "Region": "Hungary",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "George Washington's Grist Mill and Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Georges Lacroix",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Georgia Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gerhard Büchner",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gerlachus Stokerij",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gilbert Holl",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ginebra San Miguel Inc.",
      "Region": "Philippines",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Girvan",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=538&DistilleryName=Girvan+Distillery"
      ,
      "SMWS": "G7"
    },
    {
      "Distillery": "Glacier Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glan Iarran",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glann ar Mor",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glen Albyn",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "69"
    },
    {
      "Distillery": "Glen Elgin",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=85&DistilleryName=Glen+Elgin+Distillery"
      ,
      "SMWS": "85"
    },
    {
      "Distillery": "Glen Els",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glen Flagler",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glen Garioch",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=19&DistilleryName=Glen+Garioch+Distillery"
      ,
      "SMWS": "19"
    },
    {
      "Distillery": "Glen Grant",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=9&DistilleryName=Glen+Grant+Distillery"
      ,
      "SMWS": "9"
    },
    {
      "Distillery": "Glen Keith",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=81&DistilleryName=Glen+Keith+Distillery"
      ,
      "SMWS": "81"
    },
    {
      "Distillery": "Glen Kella",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glen Mhor",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=57&DistilleryName=Glen+Mhor+Distillery"
      ,
      "SMWS": "57"
    },
    {
      "Distillery": "Glen Moray",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=35&DistilleryName=Glen+Moray+Distillery"
      ,
      "SMWS": "35"
    },
    {
      "Distillery": "Glen Ord",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=77&DistilleryName=Glen+Ord+Distillery"
      ,
      "SMWS": "77"
    },
    {
      "Distillery": "Glen Scotia",
      "Region": "Scotland, Campbeltown",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=93&DistilleryName=Glen+Scotia+Distillery"
      ,
      "SMWS": "93"
    },
    {
      "Distillery": "Glen Spey",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=80&DistilleryName=Glen+Spey+Distillery"
      ,
      "SMWS": "80"
    },
    {
      "Distillery": "Glen Utin - Edelobstbrennerei Muenster",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glenallachie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=107&DistilleryName=Glenallachie+Distillery"
      ,
      "SMWS": "107"
    },
    {
      "Distillery": "Glenburgie & Glencraig",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=71&DistilleryName=Glenburgie+Distillery"
      ,
      "SMWS": "71, 104"
    },
    {
      "Distillery": "Glencadam",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=82&DistilleryName=Glencadam+Distillery"
      ,
      "SMWS": "82"
    },
    {
      "Distillery": "Glendalough",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glendronach",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=96&DistilleryName=Glendronach+Distillery"
      ,
      "SMWS": "96"
    },
    {
      "Distillery": "Glendullan",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=84&DistilleryName=Glendullan+Distillery"
      ,
      "SMWS": "84"
    },
    {
      "Distillery": "Glenesk",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=86&DistilleryName=Glenesk+Distillery"
      ,
      "SMWS": "86"
    },
    {
      "Distillery": "Glenfarclas",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1&DistilleryName=Glenfarclas+Distillery"
      ,
      "SMWS": "1"
    },
    {
      "Distillery": "Glenfiddich",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=15&DistilleryName=Glenfiddich+Distillery"
      ,
      "SMWS": "15"
    },
    {
      "Distillery": "Glenfyne (Glendarroch Glenglip)",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=135&DistilleryName=Glenfyne+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Glenglassaugh",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=21&DistilleryName=Glenglassaugh+Distillery"
      ,
      "SMWS": "21"
    },
    {
      "Distillery": "Glengoyne",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=207&DistilleryName=Glengoyne+Distillery"
      ,
      "SMWS": "123"
    },
    {
      "Distillery": "Glengyle",
      "Region": "Scotland, Campbeltown",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=188&DistilleryName=Glengyle+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Glenkinchie",
      "Region": "Scotland, Lowland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=22&DistilleryName=Glenkinchie+Distillery"
      ,
      "SMWS": "22"
    },
    {
      "Distillery": "Glenlivet",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2&DistilleryName=The+Glenlivet+Distillery"
      ,
      "SMWS": "2"
    },
    {
      "Distillery": "Glenlochy",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=62&DistilleryName=Glenlochy+Distillery"
      ,
      "SMWS": "62"
    },
    {
      "Distillery": "Glenlossie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=46&DistilleryName=Glenlossie+Distillery"
      , "SMWS": "46"
    },
    {
      "Distillery": "Glenmorangie",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=208&DistilleryName=Glenmorangie+Distillery"
      ,
      "SMWS": "125"
    },
    {
      "Distillery": "Glenmore Distilleries Co.",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1430&DistilleryName=Glenmore+Distilleries+Co%2E"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Glenora Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glenrothes",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=30&DistilleryName=Glenrothes+Distillery"
      ,
      "SMWS": "30"
    },
    {
      "Distillery": "Glentauchers",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=63&DistilleryName=Glentauchers+Distillery"
      ,
      "SMWS": "63"
    },
    {
      "Distillery": "Glenturret",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=16&DistilleryName=Glenturret+Distillery"
      ,
      "SMWS": "16"
    },
    {
      "Distillery": "Glenugie",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=99&DistilleryName=Glenugie+Distillery"
      ,
      "SMWS": "99"
    },
    {
      "Distillery": "Glenury Royal",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "75"
    },
    {
      "Distillery": "GlenWyvis Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Glina Whisky Destillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Goalong Liquor",
      "Region": "China",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Golden Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Golden Moon Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gölles",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Goodridge & Willams",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gotland Whisky",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gouden Carolus",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Graanbranderij De IJsvogel",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Graanstokerij Filliers",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Grand Traverse Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1510&DistilleryName=Grand+Traverse+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "GrandTen Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Granit Destillerie",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Great Lakes Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Great Northern Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Great Outback",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Great Southern Distilling Company",
      "Region": "Australia",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1578&DistilleryName=Great+Southern+Distilling+Company"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Great Wagon Road Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Greenbar Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Griffin Ranch Micro Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Griffo Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Grythyttan",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Guglhof",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Guillon",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gute Destilleri",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Gyokusendo Shuzo",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Habbel's Destillerie & Brennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hafendestillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hagen-Rühli",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hakushu",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2045&DistilleryName=Hakushu+Higashi+Distillery"
      ,
      "SMWS": "120"
    },
    {
      "Distillery": "Hali'imaile Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hamilton Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hanyu",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2043&DistilleryName=Hanyu+Distillery"
      ,
      "SMWS": "131"
    },
    {
      "Distillery": "Harald Keckeis",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hausbrauerei Altstadthof",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Headframe Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Healeys",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Heartwood",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Heaven Hill Distilleries, Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": "B1"
    },
    {
      "Distillery": "Hellyers Road",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Helsinki Distilling Company",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Henry Farms Prairie Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hepp",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Heritage Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hessische Spezialitätenbrennerei-Behlen GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hiebl Destillerie",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hierber Brennerei",
      "Region": "Luxembourg",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "High West Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1553&DistilleryName=High+West+Distillery+%26+Saloon"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "HighGlen Whisky Distillery",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Highland Park",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=4&DistilleryName=Highland+Park+Distillery"
      ,
      "SMWS": "4"
    },
    {
      "Distillery": "Highwood Distillers",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hillrock Estate Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hiram Walker",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hoffman Distillery - Old Commonwealth Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Höllberg Brennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hollen",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hood River Distillers Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Horstman Destilleerderij",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "House Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Humbel Distillery",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Hven",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Immortal Spirits & Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Imperial",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=65&DistilleryName=Imperial+Distillery"
      ,
      "SMWS": "65"
    },
    {
      "Distillery": "InchDairnie",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Inchgower",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=18&DistilleryName=Inchgower+Distillery"
      ,
      "SMWS": "18"
    },
    {
      "Distillery": "Ingendael",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Invergordon",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=590&DistilleryName=Invergordon+Distillery"
      ,
      "SMWS": "G5"
    },
    {
      "Distillery": "Inverleven & Lomand",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "20, 98"
    },
    {
      "Distillery": "Iron Smoke",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ironbridge Spirit Company",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Isaiah Morgan",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "ISFJORD",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Isle of Harris Distillers Ltd.",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Isle of Jura",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=31&DistilleryName=Isle+of+Jura+Distillery"
      ,
      "SMWS": "31"
    },
    {
      "Distillery": "It's 5 Artisan Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ivy Mountain",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "J et M Lehmann",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "J. Riley Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Jack Daniel's",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1080&DistilleryName=Jack+Daniels+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Jamieson Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1468&DistilleryName=Jamieson+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "James Sedgwick",
      "Region": "South Africa",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "JF Strothman Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Jim Beam",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Joh. B. Geuting",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "John Distilleries / Paul John",
      "Region": "India",
      "URL": "",
      "SMWS": "134"
    },
    {
      "Distillery": "John Emerald Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "John's Lane Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2017&DistilleryName=John%27s+Lane+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Jones Road Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2018&DistilleryName=Jones%27+Road+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Jos. A. Magnus & Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Josiah Thedford & Sons",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Journeyman Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kaerilis",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kagoshima",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kalkwijck Distillers",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kampen Destillateurs",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Karuizawa",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2065&DistilleryName=Karuizawa+Distillery"
      ,
      "SMWS": "132"
    },
    {
      "Distillery": "Kauzen Bräu",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kavalan",
      "Region": "Taiwan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kawasaki",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kellerstrass Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kentucky Bourbon Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "KGB Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Khoday India Ltd.",
      "Region": "India",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kilbeggan Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kilchoman",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1295&DistilleryName=Kilchoman+Distillery"
      ,
      "SMWS": "129"
    },
    {
      "Distillery": "Killyloch",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kinclaith",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kings County Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kingsbarns Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kininvie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=211&DistilleryName=Kininvie+Distillery",
      SMWS: ""
    },
    {
      "Distillery": "KINSIP",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kinzigbrennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kiwi Spirit Distillery Ltd.",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Klein Duimpje",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kleinbrennerei Fitzke",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kloster Zinna",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Knockando / anCnoc",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=213&DistilleryName=Knockdhu+Distillery"
      ,
      "SMWS": "101, 115"
    },
    {
      "Distillery": "Koenig Distillery and Winery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Köppel Brennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kornbrennerei Gerhard Wagner",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kornbrennerei J.J. Kemper",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "KOVAL Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kümin Weinbau",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kymsee Whisky",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Kyrö Distillery Company",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ladyburn",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=212&DistilleryName=Ladyburn+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Lagavulin",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=206&DistilleryName=Lagavulin+Distillery"
      ,
      "SMWS": "111"
    },
    {
      "Distillery": "Lagler",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lahnsteiner Brauerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lake George Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Landbrennerei Mühlhäuser",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Langatun",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Langenrohrer Erlebnisbrauerei",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Laphroaig",
      "Region": "Scotland, Islay",
      "URL": "",
      "SMWS": "29"
    },
    {
      "Distillery": "Lark Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Las Vegas Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Last Mountain Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lava Bräu Braumanufaktur",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lawrenceburg Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1133&DistilleryName=Lawrenceburg+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Laws Whiskey House",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "LB Distillers",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Leopold Bros",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lexington Brewing and Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Liber",
      "Region": "Spain",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Liberty Call Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Likörfabrik Mikolasch",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Limeburners",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Limestone Branch Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Linkwood",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=39&DistilleryName=Linkwood+Distillery"
      ,
      "SMWS": "39"
    },
    {
      "Distillery": "Littlemill",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=97&DistilleryName=Littlemill+Distillery"
      ,
      "SMWS": "97"
    },
    {
      "Distillery": "Lo Artisan Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lobangernich",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Loch Ewe Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Loch Lomond, Inchmuring, Inchmoan, Croftenga",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=209&DistilleryName=Loch+Lomond+Distillery"
      ,
      "SMWS": "G9, 112, 122, 135"
    },
    {
      "Distillery": "Lochnagar Distillery",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=689&DistilleryName=Lochnagar+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Lochindaal (Closed)",
      "Region": "Scotland",
      "URL": ""
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Longrow Distillery",
      "Region": "Scotland, Campbelown",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=498&DistilleryName=Longrow+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Lochside",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "92"
    },
    {
      "Distillery": "London Distillers (K) Ltd.",
      "Region": "Kenya",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Long Island Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Longmorn",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=7&DistilleryName=Longmorn+Distillery"
      ,
      "SMWS": "7"
    },
    {
      "Distillery": "Los 2 Compadres",
      "Region": "Mexico",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lossit",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lost Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Louisville Distilling Co LLC",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Lüthy",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Luxco",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Macallan",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=24&DistilleryName=Macallan+Distillery"
      ,
      "SMWS": "24"
    },
    {
      "Distillery": "Macardo",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Macduff / Glen Deveron",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=6&DistilleryName=Macduff+Distillery"
      ,
      "SMWS": "6"
    },
    {
      "Distillery": "Mackmyra",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mad River Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Maker's Mark Distillery, Inc.",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1086&DistilleryName=Maker%27s+Mark+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Malibreiz",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Malt Mill",
      "Region": "Scotland, Speyside",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mannochmore",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=64&DistilleryName=Mannochmore+Distillery"
      ,
      "SMWS": "64"
    },
    {
      "Distillery": "Marder Distillery",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Marillenhof - Destillerie - Kausl",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Märkische Spezialitätenbrennerei KG",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Matsui Shuzo",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "MB Roland Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "McCormick Distilling Co.",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1069&DistilleryName=McCormick+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "McDowell's",
      "Region": "India",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Meissener Spezialitätenbrennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "MGP",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Michelehof",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Michter's Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1078&DistilleryName=Michter%27s+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Middle West Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Midleton (1975-)",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mile High Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Millburn",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=87&DistilleryName=Millburn+Distillery"
      ,
      "SMWS": "87"
    },
    {
      "Distillery": "Miltonduff and Mosstowie",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=72&DistilleryName=Miltonduff+Distillery"
      ,
      "SMWS": "72, 102"
    },
    {
      "Distillery": "Minhas Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mississippi River Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Missouri Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Miyagikyo",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2064&DistilleryName=Miyagikyo+Distillery"
      ,
      "SMWS": "124"
    },
    {
      "Distillery": "Miyashita Sake Brewery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Monde Shuzou",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Monkey Hollow Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Moon Harbour",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mortlach",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=76&DistilleryName=Mortlach+Distillery"
      ,
      "SMWS": "76"
    },
    {
      "Distillery": "Mosgaard Whisky",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mosswood Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mosterei & Brennerei Kobelt",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mountain Laurel Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mulindry",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Murree Brewery",
      "Region": "Pakistan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Myer Farm Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Myken Destilleri",
      "Region": "Norway",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Mykulynetsky Brovar",
      "Region": "Ukraine",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nagahama",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nagahama Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nahmias et Fils",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nant Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nantou Distillery",
      "Region": "Taiwan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nelson's Green Brier Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nephin Whiskey Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nestville Distillery",
      "Region": "Slovakia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "New Deal Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "New England Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "New Holland Brewing",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "New World Whisky Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "New York Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Newton",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Niederrhein Destille",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ninkasi",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nishinomiya",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "NORDIK Edelbrennerei & Spirituosenmanufaktur",
      "Region": "Germany",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1589&DistilleryName=Nordisk+Br%E6nderi"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Nordisk Brænderi",
      "Region": "Denmark",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1589&DistilleryName=Nordisk+Br%E6nderi"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Nordmarkens Destilleri AB",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Norrtelje Brenneri",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "North British",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "G1"
    },
    {
      "Distillery": "North of 7 Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "North of Scotland",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "North Port",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "74"
    },
    {
      "Distillery": "North Shore Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Northmaen",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Number Nine Spirituosenmanufaktur GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nun's Island Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Nyborg Distillery",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "O.F.C.- Stagg Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "O.Z.Tyler Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Oban",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=216&DistilleryName=Oban+Distillery"
      ,
      "SMWS": "110"
    },
    {
      "Distillery": "Obst-Korn Brennerei ZAISER",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Off The Clock Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ohishi Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Okanagan Spirits",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Comber",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Crow Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1089&DistilleryName=Old+Crow+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Old Dominick Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Forge Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Grand-Dad Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1091&DistilleryName=Old+Grand+Dad+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Old Hobart Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Midleton (1825-1975)",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Pogue",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1409&DistilleryName=The+Old+Pogue+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Old Pulteney",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=52&DistilleryName=Old+Pulteney+Distillery"
      ,
      "SMWS": "52"
    },
    {
      "Distillery": "Old Raven",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Rip van Winkle",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1090&DistilleryName=Old+Rip+van+Winkle"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Old Sandhill",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old Sugar Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1558&DistilleryName=Old+Sugar+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Old Tahoe Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Old World Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ole Smoky Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Oola Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Oregon Spirit Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Öufi-Brauerei",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ozark Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "P&M",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Palais Benedictine",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Palmetto Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Panimoravintola Beer Hunter's",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Panimoravintola Koulu",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Panther Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1199&DistilleryName=Parcher+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Parkmore",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=134&DistilleryName=Parkmore+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Parliament Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Patrick Breindl",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Peach Street Distillers",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1514&DistilleryName=Peach+Street+Distillers"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Peloni",
      "Region": "Italy",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pelter Distillery",
      "Region": "Israel",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pemberton Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Penderyn",
      "Region": "United Kingdom",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2062&DistilleryName=Penderyn+Distillery"
      ,
      "SMWS": "128"
    },
    {
      "Distillery": "Persse's Galway Whiskey Ltd.",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Peter Affenzeller",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pfanner",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pfau Brennerei",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Philadelphia Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Piedmont Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pittsburgh Distilling Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pittyvaich",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "90"
    },
    {
      "Distillery": "Popcorn Sutton Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Port Chilkoot Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Port Dundas",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=571&DistilleryName=Port+Dundas+Distillery"
      ,
      "SMWS": "G6"
    },
    {
      "Distillery": "Port Ellen",
      "Region": "Scotland, Islay",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=43&DistilleryName=Port+Ellen+Distillery"
      ,
      "SMWS": "43"
    },
    {
      "Distillery": "Potter",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Pradlo Distillery",
      "Region": "Czech Republic",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Praskoveya Winery",
      "Region": "Russian Federation",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Preussische Whisky Destillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Prichard's",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Prince Edward Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Privatbrauerei Friedrich Düll",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Puni",
      "Region": "Italy",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "R&B Distillers",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "R. Jelínek",
      "Region": "Czech Republic",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Raasay Distillery",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rabbit Hole Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rabel",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Radermacher",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Radico Khaitan Ltd",
      "Region": "India",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ranger Creek",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1565&DistilleryName=Ranger+Creek+Brewing+%26+Distilling"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Ransom",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Re:Find",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rebecca Creek Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Reece's Distilled Spirits LLC",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Reisetbauer",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Reservoir Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rittmeister Destille",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rock Town",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1611&DistilleryName=Rock+Town+Distillery"
      ,
      "SMWS": "B3"
    },
    {
      "Distillery": "Rogue Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rose Valley Feinbrand Manufaktur Brabant",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rosebank",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=25&DistilleryName=Rosebank+Distillery"
      ,
      "SMWS": "25"
    },
    {
      "Distillery": "Roseisle",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rosenbrennerei Einbach",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Rothaus",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "RoughStock",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Royal Brackla",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=55&DistilleryName=Royal+Brackla+Distillery"
      ,
      "SMWS": "55"
    },
    {
      "Distillery": "Royal Brewhouse",
      "Region": "Indonesia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Royal Lochnagar",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=103&DistilleryName=Royal+Lochnagar+Distillery"
      ,
      "SMWS": "103"
    },
    {
      "Distillery": "Rugenbräu",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ryan & Wood Distilleries",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sächsische Spirituosenmanufaktur",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sagamore Spirit Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Saint James Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Salm Bräu",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Samuel Smith & Son",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "San Diego Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Santa Fe Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Santa Fe Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Säntisblick Destillerie Ltd.",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sasanokawa Shuzo",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sauerländer Edelbrand GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Saxa Vord",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Scapa",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=17&DistilleryName=Scapa+Distillery"
      ,
      "SMWS": "17"
    },
    {
      "Distillery": "Schlitzer Korn- & Edelobstbrennerei GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Schlossbrauerei Autenried GmbH",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Schwane Destillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Scissortail Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Seattle Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Second Chance Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sempione Distillery",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Seven Brothers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Shelter Point Distillery",
      "Region": "Canada",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1475&DistilleryName=Shelter+Point+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Shinshu Mars",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Shirakawa",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2046&DistilleryName=Shirakawa+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Shizuoka Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Short Mountain Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sikkim",
      "Region": "India",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Silver Trail Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Six & Twenty Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Skip Rock Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Slane Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sliabh Liag Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Slyrs",
      "Region": "Germany",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1324&DistilleryName=Slyrs+Destillerie+GmbH+%26+Co%2E+KG"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Small Brook",
      "Region": "Luxembourg",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Smögen Whisky",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Smooth Ambler Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Smugglers' Notch Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sonnenbräu",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sonnenscheiner",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sonoma County Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sons of Liberty",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Artisan Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Coast Distillers",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Distilleries Ltd.",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Grace Distilleries",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Grain Spirits",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Southern Tier Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sperbers Destillerie",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sperling Silver Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Speyburn",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=88&DistilleryName=Speyburn+Distillery"
      ,
      "SMWS": "88"
    },
    {
      "Distillery": "Speyside Distillery",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=218&DistilleryName=Speyside+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Spezialitätenbrauerei Eckart",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Spezialitätenbrennerei Weidhof",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Spirit Hound Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Spirit Works Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Split Rock Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Spreewald Brennerei",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Spreewood Distillers",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Springbank, Longrow, Hazelburn",
      "Region": "Scotland, Campbeltown",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=27&DistilleryName=Springbank+Distillery"
      ,
      "SMWS": "27, 114, 126"
    },
    {
      "Distillery": "Square One",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "St Magdalene",
      "Region": "Scotland",
      "URL": "",
      "SMWS": "49"
    },
    {
      "Distillery": "St. Augustine Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "St. George Spirits",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1137&DistilleryName=St%2E+George+Spirits"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "St. George's Distillery",
      "Region": "United Kingdom",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1456&DistilleryName=St%2E+George%27s+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "St. Kilian Distillers",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "St. Patrick's Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "St. Petersburg Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stadler",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stadsbrouwerij De Hemel",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stammheimer",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stark Spirits Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Starkenberg",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Starlight Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stauning",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Steam Exchange Brewery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stein Distillery Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Steinhauser",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Steinwälder Hausbrennerei Schraml",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Still Waters Distillery",
      "Region": "Canada",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1551&DistilleryName=Still+Waters+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "StilltheOne",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stillwater Spirits - Moylan's Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stitzel-Weller",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stoaninger",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stock Plzeň",
      "Region": "Czech Republic",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=859&DistilleryName=Stock+Plzen+a%2Es%2E"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Stöfflbräu",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stokerij De Molenberg",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stokerij De Onrust",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stokerij Erve Sculte",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stokerij Hondsrug",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stone Barn Brandyworks",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stonecutter Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Stornoway (Ness)",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=490&DistilleryName=Stornoway+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Stranahan's Colorado Whiskey",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Strathclyde",
      "Region": "Scotland, Speyside",
      "URL": "",
      "SMWS": "G10"
    },
    {
      "Distillery": "Strathearn Distillery",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1598&DistilleryName=Strathearn+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Stratheden",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Strathisla",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=58&DistilleryName=Strathisla+Distillery"
      ,
      "SMWS": "58"
    },
    {
      "Distillery": "Strathmill",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=100&DistilleryName=Strathmill+Distillery"
      ,
      "SMWS": "100"
    },
    {
      "Distillery": "Strong Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sugarlands Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sullivans Cove",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Surselva Bräu",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Sweet Potato Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Swhisky",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Taconic Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tahoe Moonshine Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Talisker",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=14&DistilleryName=Talisker+Distillery"
      ,
      "SMWS": "14"
    },
    {
      "Distillery": "Tallant",
      "Region": "Scotland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=445&DistilleryName=Tallant+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Tamdhu",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=8&DistilleryName=Tamdhu+Distillery"
      ,
      "SMWS": "8"
    },
    {
      "Distillery": "Tamnavulin",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=34&DistilleryName=Tamnavulin+Distillery"
      , "SMWS": "34"
    },
    {
      "Distillery": "Tanduay Distillers, Inc.",
      "Region": "Philippines",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tatoosh",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tawny Corn",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Teaninich",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=59&DistilleryName=Teaninich+Distillery"
      ,
      "SMWS": "59"
    },
    {
      "Distillery": "Teeling Whiskey Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Teerenpeli",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tekel",
      "Region": "Turkey",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Telser Distillery",
      "Region": "Liechtenstein",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Temperance Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Terreni alla Maggia",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tevsjö Destilleri",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Belgian Owl",
      "Region": "Belgium",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Cooper Spirits Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Dingle Whiskey Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1525&DistilleryName=Dingle+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "The Fat Pig Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Glasgow Distillery Co.",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Golan Heights Distillery",
      "Region": "Israel",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Grove Experience",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Lakes Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Liberty Distillery",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Lindores Distilling Co. Ltd.",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The London Distillery Company",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Lübbehusen Malt Distillery",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Milk & Honey Distillery",
      "Region": "Israel",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Original Texas Legend Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Oxford Artisan Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Quiet Man Irish Whiskey Co. Ltd.",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Splinter Group Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Vogel",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "The Westfalian",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thirteenth Colony Distilleries",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thomas Sippel",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thomas Street (George Roe) Distillery",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2038&DistilleryName=Thomas+Street+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Thomas Wilhelm",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thomson Whisky New Zealand Ltd",
      "Region": "New Zealand",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1614&DistilleryName=Thomson+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Thoran Distillery ehf",
      "Region": "Iceland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Three Boys Farm Distillery - Whiskey Thief Distilling Company, Inc.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thuisbrunner Elch-Bräu",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thuisbrunner Elch-Bräu",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thumb Butte Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Thunder Road Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tiger Juice Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Timboon Railway Shed Distillery",
      "Region": "Australia",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1528&DistilleryName=Timboon+Railway+Shed+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Tin Shed Distilling Co.",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tipperary Boutique Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tirado Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "To Tårn Bryggeri AS",
      "Region": "Norway",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tobermory & Ledaig",
      "Region": "Scotland, Isles",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=42&DistilleryName=Tobermory+Distillery"
      ,
      "SMWS": "42"
    },
    {
      "Distillery": "Tobias Schmid & Sohn",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tom's Foolery Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tomatin",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=11&DistilleryName=Tomatin+Distillery"
      ,
      "SMWS": "11"
    },
    {
      "Distillery": "Tomintoul",
      "Region": "Scotland, Speyside",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=89&DistilleryName=Tomintoul+Distillery"
      ,
      "SMWS": "89"
    },
    {
      "Distillery": "Top Of The Hill Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Torabhaig",
      "Region": "Scotland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tormore",
      "Region": "Scotland, Isles",
      "URL": "",
      "SMWS": "105"
    },
    {
      "Distillery": "Toronto Distillery Co.",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Toulvaddie Distillery",
      "Region": "United Kingdom",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Trail Town Still",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Traverse City Whiskey Co. (TCWC)",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Trebitsch Old Town Distillery",
      "Region": "Czech Republic",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Triple Eight Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Trolden Distillery",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tsunuki",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tullamore Dew (1829 - 1954)",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tullamore Dew (2014 - present)",
      "Region": "Ireland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2039&DistilleryName=Tullamore+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Tullibardine",
      "Region": "Scotland, Highland",
      "URL": "",
      "SMWS": "28"
    },
    {
      "Distillery": "Turv Exloo Distillery",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Tuthilltown Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Twin Valley Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Two Doves Destilerija",
      "Region": "Serbia And Montenegro",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Two James Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Uerige",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Union Distillery Maltwhisky do Brasil",
      "Region": "Brazil",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Union Horse Distilling Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Urban Distilleries",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Us Heit Distillery",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Valamo Monastery Distillery",
      "Region": "Finland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Valentine Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Vallei Distilleerderij",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Van Brunt Stillhouse",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "van Kleef",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Venus Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Victoria Distillers",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Vielanker Brauhaus",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Viktor Senn",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Vingården Lille Gadegård",
      "Region": "Denmark",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1530&DistilleryName=Ving%E5rden+Lille+Gadeg%E5rd"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Vinn",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Virginia Distillery Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Virginia Sweetwater Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wädi-Brau-Huus",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wakatsuru Distillery",
      "Region": "Japan",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Waldviertler Roggenhof",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wallner",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Walsh Whiskey Distillery",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Walter Seeger",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wannborga Bränneri & Vingård",
      "Region": "Sweden",
      "URL": "",
      "SMWS": ""
    }, {
      "Distillery": "Warenghem",
      "Region": "France",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Waterford",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Watershed Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2041&DistilleryName=Waterside+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Wayne Gretzky Estate",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Weidenauer",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Weinbau zur Krone",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Weingut Dotzauer",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Weingut Etl",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Weingut Mößlein",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "West Cork Distillers",
      "Region": "Ireland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "West Virginia Distilling Co., LLC",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1388&DistilleryName=West+Virginia+Distilling+Co%2E%2C+LLC"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Western Son Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Western Spirits Beverage Company",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Westland Distillery",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1601&DistilleryName=Westland+Distillery"
      ,
      "SMWS": "133"
    },
    {
      "Distillery": "Weutz",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Whipper Snapper Distillery",
      "Region": "Australia",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Whisky Castle",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Whisky oet Grunn",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "WhistlePig",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Whistling Andy",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Whitmeyer's",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Widow Jane Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wieser",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wijnhuis Texel",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wild Turkey Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Willett Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Willie's",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Willowbank",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Willy Macheiner",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wishkah River Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Witherspoon Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wolfburn",
      "Region": "Scotland, Highland",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1586&DistilleryName=Wolfburn+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Wood Hat Spirits",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Woodford Reserve",
      "Region": "United States",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=1101&DistilleryName=The+Woodford+Reserve+Distillery"
      ,
      "SMWS": ""
    },
    {
      "Distillery": "Woodinville Whiskey Co.",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Woodstone Creek",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wood’s High Mountain Distillery",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Workshops Distillery",
      "Region": "New Zealand",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wylie Howell Whiskey",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Wyoming Whiskey",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Yahara Bay Distillers",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Yamazaki",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2051&DistilleryName=Yamazaki+Distillery"
      ,
      "SMWS": "119"
    },
    {
      "Distillery": "Yellow Rose Distilling",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Yoichi",
      "Region": "Japan",
      "URL": "http://www.whiskyportal.com/distillery.asp?DistilleryID=2052&DistilleryName=Yoichi++Distillery"
      ,
      "SMWS": "116"
    },
    {
      "Distillery": "Yukon Brewing & Distilling",
      "Region": "Canada",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Z'Graggen",
      "Region": "Switzerland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Z.Kozuba i Synowie",
      "Region": "Poland",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Zauser",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Zeitzer Whisky Manufactur",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Zuidam Distillery",
      "Region": "Netherlands",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Zur Strandburg",
      "Region": "Germany",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Zweiger",
      "Region": "Austria",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "ÆppelTreow",
      "Region": "United States",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Ærø Whisky",
      "Region": "Denmark",
      "URL": "",
      "SMWS": ""
    },
    {
      "Distillery": "Port Askaig",
      "Region": "Scotland, Islay",
      "URL": "",
      "SMWS": ""
    }
  ];
}