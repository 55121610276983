import {
    IonItemSliding, IonCard, IonList, IonLabel, IonIcon, IonItemOptions,
    IonItemOption, IonItem, IonListHeader
} from "@ionic/react";
import { trash } from "ionicons/icons";
import { useContext } from "react";
import { UidContext } from "../../hooks/UserDetailsContexts";
import { saveWishlistList } from "../../data/WishlistUtils";
import {DataContext} from '../../hooks/DataContext'

export default function WishlistList ({searchText} : {searchText: string}) {
    const [uid] = useContext(UidContext);
    const {wishlist} = useContext(DataContext);

    const passthruStyle = {
        opacity: "85%"
    }

    const searchLower = searchText.toLowerCase();

    function itemMatch(item: string, searchText: string) {
        return searchText.length === 0 || item.toLowerCase().indexOf(searchText) >= 0;
    }

    function addItem(item: string) {
        return (
            <IonItemSliding key={"sliding-" + item}>
                <IonItemOptions>
                    <IonItemOption color="danger" onClick={() => deleteItem(item)}>
                        <IonIcon slot="icon-only" icon={trash} />
                    </IonItemOption>
                </IonItemOptions>
                <IonItem key={item} onClick={() => console.log("Selected " + item)}>
                    <IonLabel><h3>{item}</h3></IonLabel>
                </IonItem>
            </IonItemSliding>
        )
    }
    function deleteItem(key: string) {
        const i = wishlist.list.indexOf(key);
        if (i > -1) {
            wishlist.list.splice(i, 1);
            saveWishlistList(uid, wishlist.list);
            console.log("DeleteItem " + key);
        }
    }

    return (
        <IonCard style={passthruStyle}>
            <IonList>
                <IonListHeader >
                    <IonLabel color="primary">{wishlist.list.length} items on Wishlist</IonLabel>
                </IonListHeader>

                {wishlist.list.map((item) => {
                    return itemMatch(item, searchLower) && addItem(item);
                })}

            </IonList>
        </IonCard>
    )

}

