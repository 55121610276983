import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'

/****************** Firebase config **********/
export const firebaseConfig = {
  apiKey: "AIzaSyDfxNrAMF5Z0HqDt0pAmAmfgpNjtL2k4eI",
  authDomain: "mywhisky-cloud.firebaseapp.com",
  databaseURL: "https://mywhisky-cloud-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mywhisky-cloud",
  storageBucket: "mywhisky-cloud.appspot.com",
  messagingSenderId: "773006380869",
  appId: "1:773006380869:web:d538f0988043d4ee74b82c",
  measurementId: "G-YCL5W91HY3"

};

export async function initFirebase() {
  if (!firebase.apps.length) {
    console.log("Initializing firebase")
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().enablePersistence()
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
        } else if (err.code === 'unimplemented') {
            console.log("The current browser does not support all of the features required to enable persistence");
        }
    });
    console.log("firestore persistence enabled");
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    console.log("firebase auth perisistence enabled");
    console.log("firebase inintialized apps:" + firebase.apps.length);
    console.log("User: " + firebase.auth().currentUser?.email)
  }
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

}

