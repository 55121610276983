import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import {
  personOutline, personSharp, fileTrayStackedOutline, fileTrayStackedSharp, helpOutline, helpSharp
} from 'ionicons/icons';
import {
  iosBarrelsSvg, iosBottlesSvg, iosMixingSvg, iosWishlistSvg, mdBarrelsSvg,
  mdBottlesSvg, mdMixingSvg, mdWishlistSvg
} from '../assets/icon/customIcons';

import './Menu.css';


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}


// TODO: Ion Attribtion to 
// <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
//         Icon by <a href="https://freeicons.io/profile/3031">anju.p</a> on <a href="https://freeicons.io">freeicons.io</a>
// Empty by Adrien Coquet from the Noun Project
// gas gauge by Iconic from the Noun Project
// gin by Nook Fulloption from the Noun Project
// Whisky by Weltenraser from the Noun Project



const appPages: AppPage[] = [
  {
    title: 'Whiskies',
    url: '/whiskies',
    iosIcon: iosBottlesSvg,
    mdIcon: mdBottlesSvg
  },
  {
    title: 'Distilleries',
    url: '/distilleries',
    iosIcon: iosBarrelsSvg,
    mdIcon: mdBarrelsSvg
  }, {
    title: 'Bottlers',
    url: '/bottlers',
    iosIcon: iosMixingSvg,
    mdIcon: mdMixingSvg
  }, {
    title: 'Shelves',
    url: '/shelves',
    iosIcon: fileTrayStackedOutline,
    mdIcon: fileTrayStackedSharp,
  }, {
    title: 'Wishlist',
    url: '/wishlist',
    iosIcon: iosWishlistSvg,
    mdIcon: mdWishlistSvg
  }, {
    title: 'Account',
    url: '/login',
    iosIcon: personOutline,
    mdIcon: personSharp,
  }, {
    title: 'About',
    url: '/about',
    iosIcon: helpOutline,
    mdIcon: helpSharp,
  },

];


const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="menu-list">
          <IonListHeader>MyWhisky.cloud</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''}
                  routerLink={appPage.url} routerDirection="none" lines="none" detail={false}
                  key={appPage.title}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
