import React from 'react';

import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { initFirebase } from "./firebase/firebase-config";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './app.css'

import { defineCustomElements } from '@ionic/pwa-elements/loader';

// Hooks
import { DataProvider } from './hooks/DataContext';
import { UidProvider, UsernameProvider } from './hooks/UserDetailsContexts';

// Menu
import Menu from './components/Menu';

//Pages
import Distilleries from './pages/Distilleries/Distilleries';
import Bottlers from './pages/Bottlers/Bottlers';
import Shelves from './pages/Shelves/Shelves';
import Whiskies from './pages/Whiskies/Whiskies';
import Wishlist from './pages/Wishlist/Wishlist';
import Login  from './pages/Login/Login';
import WhiskyEdit from './pages/WhiskyEdit/WhiskyEdit';
import About from './pages/About/About';
import Tos from './pages/About/tos';
import Privacy from './pages/About/privacy';
import Cookies from './pages/About/cookies';
import NewVersionChecker from './components/newVersion';
import {createTheme, CssBaseline, ThemeProvider, useMediaQuery} from '@mui/material'

// Initialize Firebase
// Ensure that you do not login twice.
initFirebase();

setupIonicReact()

export default function App() {

  /* Include the Ionic PWA version of capacitor */

  defineCustomElements(window);
  /* toggle material-ui dark mode */
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
            },
          }),
      [prefersDarkMode],
  );

  function AppRoutes() {
    return (
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu/>
            <IonRouterOutlet id="main">
              <Route exact path="/distilleries">
                <Distilleries/>
              </Route>
              <Route exact path="/login">
                <Login/>
              </Route>
              <Route path="/whiskies">
                <Whiskies/>
              </Route>
              <Route path="/whiskyedit">
                <WhiskyEdit/>
              </Route>
              <Route path="/bottlers">
                <Bottlers/>
              </Route>
              <Route path="/shelves">
                <Shelves/>
              </Route>
              <Route path="/wishlist">
                <Wishlist/>
              </Route>
              <Route path="/about">
                <About/>
              </Route>
              <Route path="/tos">
                <Tos/>
              </Route>
              <Route path="/privacy">
                <Privacy/>
              </Route>
              <Route path="/cookies">
                <Cookies/>
              </Route>
              <Route path="/tos.html">
                <Tos/>
              </Route>
              <Route path="/privacy.html">
                <Privacy/>
              </Route>
              <Route path="/cookies.html">
                <Cookies/>
              </Route>


              <Route exact path="/">
                <Redirect to="/whiskies"/>
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
    )
  }

  return (
      <IonApp>
        {/* Add the data providers */}
        <UsernameProvider>
          <UidProvider>
            <DataProvider>
              <>
                {/* material-ui them */}
                <ThemeProvider theme={theme}>
                  <CssBaseline/>

                  {/* New version checker */}
                  <NewVersionChecker/>


                  { /* Do the routes */}
                  <AppRoutes/>

                </ThemeProvider>
              </>
            </DataProvider>
          </UidProvider>
        </UsernameProvider>
      </IonApp>
  )


}

