import { createGenericListDetaults, loadGenericList, saveGenericList } from "./dataArrayUtils";
const DefaultShelves = ["Top", "Middle", "Bottom", "Empties"];
export const SHELVES_VERSION = 1;
export const SHELVES_KEY = 'shelves';

export async function createDefaultShelves(uid: string) {
    createGenericListDetaults(uid, SHELVES_KEY, SHELVES_VERSION, DefaultShelves);
}

export function saveShelvesList(uid: string, shelvesList: string[]) {
    if(shelvesList.indexOf("Empties") === -1) shelvesList.push("Empties");
    saveGenericList(uid, SHELVES_KEY, shelvesList);
}

export async function loadShelvesList(uid: string): Promise<string[]> {
    return loadGenericList(uid, SHELVES_KEY);
}
