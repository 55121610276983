import firebase from "firebase/compat/app";
import 'firebase/compat/database'

export const whiskyVersion = 1;
export const WHISKY_KEY = 'whisky';

export async function deleteWhisky(uid : string, uuid : string)
{
    console.log("DeleteItem " + uuid);
    var docToRemove = firebase.firestore().collection("users/" + uid + "/" + WHISKY_KEY + "/").doc(uuid);
    const data = await docToRemove.get();
    console.log("DeleteItem " + uuid + " " + data.get("name"));

    docToRemove.delete();

}

export function saveAddWhisky(uid : string, data : any) {
    if(uid === "") throw new Error("Not Logged In");
    if(data.name === "") throw new Error("Name is required");
    if(data.distillery === "") throw new Error("Distillery is required");
    if(data.shlf === "") throw new Error("Shelf is required");



    const path = "users/" + uid + "/" + WHISKY_KEY + "/" + data.uuid;
    console.log("create:" + path);
    let doc = firebase.firestore().doc(path);
    doc?.set(data, { merge: true })
}
