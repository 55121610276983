import React, { useState, useContext } from 'react';
import {
  IonContent, IonHeader, IonPage, IonToolbar, IonSearchbar, IonMenuButton, IonButtons, IonButton, IonFab, IonFabButton, IonIcon
} from '@ionic/react';
import './Bottlers.css';
import AddBottler from './BottlerAdd';
import BottlerList from "./BottlerList";
import { UidContext } from '../../hooks/UserDetailsContexts';
import { add } from 'ionicons/icons';
import {DataContext} from '../../hooks/DataContext'

export default function Bottlers() {
  const [searchText, setSearchText] = useState('');
  const [uid] = useContext(UidContext);
  // const [username] = useContext(UsernameContext)
    const { setShowAddBottler } = useContext(DataContext);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          Bottlers
        </IonToolbar>
        {uid > "" ?
          <>
            <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
              placeholder="Search by name" showClearButton="always" showCancelButton="never"></IonSearchbar>
            <AddBottler />
          </>
          : ""}
      </IonHeader>
      <IonContent class="bottlers">
        {/* Fab "+" button to add */}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => setShowAddBottler(true)}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        { /* Only show list if logged in */ }
        {uid > "" ? <BottlerList searchText={searchText} /> :
          <IonButton routerLink="/login">Login</IonButton>
        }
      </IonContent>

    </IonPage>
  )

}
