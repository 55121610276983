import { useContext, useState } from "react";
import {
    IonInput, IonButton, IonCard, IonCardContent,
    IonToast,
    IonItem,
    IonLabel
} from "@ionic/react";
import { DefaultDistilleries } from "../../data/defaultDistilleries";
import { loadDistilleriesList, saveDistilleriesList } from "../../data/DistilleryUtils";
import { UidContext } from "../../hooks/UserDetailsContexts";
import {Autocomplete, TextField} from '@mui/material'
import {DataContext} from '../../hooks/DataContext'
const { v4: uuidv4 } = require('uuid');


export default function AddDistillery  () {

    const InitialDistilleryState = {
        name: "",
        region: "",
        smws: "",
    };
    const [distillery, setDistillery] = useState(InitialDistilleryState);
    const [uid] = useContext(UidContext);
    const [showErrorTost, setShowErrorToast] = useState(false);

    /* Show form toggle */
    const {setShowAddDistillery} = useContext(DataContext);

    const comboBoxStyle = {
        width: "100%"
    }

    const setName = (newName: string) => {
        distillery.name = newName;
    }
    const setRegion = (newRegion: string) => {
        distillery.region = newRegion;
    }
    const setSmws = (newSmws: string) => {
        distillery.smws = newSmws;
    }


    function createRecord(name: string, data: {}) {
        if (uid === "") {
            return;
        }

        loadDistilleriesList(uid).then((list) => {
            list.set(name, data);
            saveDistilleriesList(uid, list);
        });
    }

    const saveDistillery = () => {
        const data = {
            name: distillery.name,
            region: distillery.region,
            smws: distillery.smws,
            nameLower: distillery.name.toLowerCase(),
            regionLower: distillery.region.toLowerCase(),
            smwsLower: distillery.smws.toLowerCase(),
            uuid: uuidv4()
        }
        try {
            if (data.name === "" || data.region === "") {
                setShowErrorToast(true);
                return;
            }
            createRecord(data.name, data);
            console.log("saved distillery: " + JSON.stringify(distillery))
            newDistillery();
            setShowAddDistillery(false);
        } catch (e) {
            console.log("error saving distillery: " + e)
        }
    }
    const newDistillery = () => {
        setDistillery(InitialDistilleryState);
    }

    /* The add distillery form */
    function showAddForm() {
        return (
            <IonCard>
                <IonCardContent>
                    <IonItem>
                        <IonLabel position="floating">Name</IonLabel>
                        <IonInput clearInput={true} name="name" value={distillery.name} placeholder="Name"
                                  onIonChange={e => setName(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <Autocomplete id="region" style={comboBoxStyle}
                                      options={DefaultDistilleries.regions}
                                      clearOnEscape={true}
                                      freeSolo={true}
                                      onChange={(o, v) => setRegion(v ? v : "")}
                                      renderInput={(params) => <TextField {...params} label="Region"
                                                                          variant="standard"/>}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">SMWS Number</IonLabel>
                        <IonInput clearInput={true} name="smws" value={distillery.smws}
                                  onIonChange={e => setSmws(e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonButton onClick={saveDistillery}>Save</IonButton>

                    <IonButton color="light" onClick={() => setShowAddDistillery(false)}>Cancel</IonButton>
                </IonCardContent>
            </IonCard>

        )
    }


    return (
        <>
            <IonToast isOpen={showErrorTost}
                      onDidDismiss={() => setShowErrorToast(false)}
                      message="Distillary name and region are required."
                      duration={1500}
                      position="bottom"
                      color="warning"
            />
            {showAddForm()}
        </>

    )
}

