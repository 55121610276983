import firebase from "firebase/compat/app";
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import React, { createContext, useEffect, useState } from "react";
import { createDefaultBottlers } from "../data/BottlerUtils";
import { createDefaultDistilleries } from "../data/DistilleryUtils";
import { createDefaultShelves } from "../data/ShelfUtils";
import { createDefaultWishlist } from "../data/WishlistUtils";
import { initFirebase } from "../firebase/firebase-config";
import { IonToast } from "@ionic/react";

export const UidContext = createContext<[string,
  React.Dispatch<React.SetStateAction<string>>]>({} as any);



export const UidProvider = (props: {children?: React.ReactNode}) => {
  const [uid, setUid] = useState('');
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);

  initFirebase();

  /****
   * Initialize a user
   */
  function initUid(uid: string) {
    if (uid === "") {
      return;
    }
    let userCollection = firebase.firestore().collection("users");
    var doc = userCollection.doc(uid);

    // Initialize the users distilleries node
    doc.set({
      uid: uid,
      email: firebase.auth().currentUser?.email,
    }, { merge: true })
    console.log("distilleries user doc added/updated")
    createDefaultDistilleries(uid);
    createDefaultBottlers(uid);
    createDefaultShelves(uid);
    createDefaultWishlist(uid);
  }

  /******
   * Detect user state change.
   * Save UID, init databse, show loading toast.
   */
  useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          if (uid !== authUser.uid) {
            setUid(authUser.uid);
            setShowLoadingMessage(true);
            initUid(authUser.uid);
            console.log("context uid changed=" + authUser.uid)
          }
        } else {
          setUid('');
          console.log("context uid changed=")
        }
      });
    }
  });

  return (
    <UidContext.Provider value={[uid, setUid]}>
      <>
        <IonToast isOpen={showLoadingMessage}
          onDidDismiss={() => setShowLoadingMessage(false)}
          message="Loading databases."
          duration={2000}
          position="middle"
        />
        {props.children}
      </>
    </UidContext.Provider>
  );
}

/******** Here is the context for the username ************/
export const UsernameContext = createContext<[string,
  React.Dispatch<React.SetStateAction<string>>]>({} as any);

export const UsernameProvider = (props: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState('');

  initFirebase();

  useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          setCurrentUser(authUser.email ? authUser.email : '');
          console.log("context user changed=" + authUser.email)
        } else {
          setCurrentUser('');
          console.log("context user changed=")
        }
      });
    }
  });

  return (
    <UsernameContext.Provider value={[currentUser, setCurrentUser]}>
      {props.children}
    </UsernameContext.Provider>
  );
}

